/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useFormik } from 'formik'
import { newServices, storageServices } from 'services'
import { Option } from 'types/conmon'
import { useDisclosure } from '@chakra-ui/react'
import { DataChartMoreType, DataChartType, InfoModalType } from 'types/chart'
import {
  WordCategoryChartResponse,
  WordLesChartResponse,
  WordTimeLineChartResponse,
} from 'services/companyServices'
import { options, optionsPdf } from './constant'

export type NewsDetailWordMapProps = {
  year?: number
  quarter: number
  typeNews: boolean
  companyId?: number
  reportType: number
  companyName: string | undefined
  isOpenDrawer: boolean
}

export interface FormValues {
  term: string
  sort_by: number
  quarters: string
  targetYear: string
  display_size: string
  analysis_menu: string
  multi_company: Option[]
  number_of_word: string
}

export const useNewsDetailWordMap = (props: NewsDetailWordMapProps) => {
  const { isOpenDrawer, companyName, typeNews, year, quarter, reportType } =
    props
  const { id } = useParams()
  const accessToken = storageServices.getAccessToken()
  const optionsAnalysisMenu = typeNews ? optionsPdf : options
  const abortControllerRef = useRef<AbortController>(new AbortController())

  const formik = useFormik<FormValues>({
    initialValues: {
      term: '',
      sort_by: 1,
      quarters: '',
      targetYear: '',
      display_size: '0',
      analysis_menu: '0',
      multi_company: [],
      number_of_word: '2',
    },
    /**
     *
     */
    onSubmit() {},
  })

  const { setFieldValue, values, setValues } = formik

  const [reloadChart, setReloadChart] = useState(false)
  const [valueSwitch, setValueSwitch] = useState(false)
  const [windowWidthSize, setWindowWidthSize] = useState(0)
  const [multiCompanyOptions, setMultiCompanyOptions] = useState<Option[]>([])

  const [infoModal, setInfoModal] = useState<InfoModalType>({
    isShowAll: false,
    typeChart: '',
    index: null,
  })

  const [dataChart, setDataChart] = useState<DataChartType>({
    wordLesChart: {
      dataWordCloud: [],
      dataEchart: [],
      hashed_data: '',
    },
    dataListWordCloud: {
      data: [],
      hashed_data: '',
    },
    dataTimeLine: {
      data: [],
      hashed_data: '',
    },
    loading: false,
  })

  const [dataChartMore, setDataChartMore] = useState<DataChartMoreType>({
    loading: false,
    data: [],
    hashed_data: '',
  })

  const [responseDataWordCloud, setResponseDataWordCloud] = useState<{
    option1: WordLesChartResponse | null
    option2: WordCategoryChartResponse | null
    option3: WordTimeLineChartResponse[]
  }>({
    option1: null,
    option2: null,
    option3: [],
  })

  const [valueExplanation, setValueExplanation] = useState<{
    loading: boolean
    data: string
    done: boolean
  }>({ loading: false, data: '', done: true })

  const {
    isOpen: isZoom,
    onOpen: onOpenZoom,
    onClose: onCloseZoom,
  } = useDisclosure()

  const displaySizeChart = React.useMemo(() => {
    if (values.analysis_menu === '0') return 1
    switch (values.display_size) {
      case '0':
        return 1
      case '1':
        return 2
      case '2':
        return 5

      default:
        return 2
    }
  }, [values.analysis_menu, values.display_size])

  const containerChartWidth = React.useMemo(() => {
    const width = isOpenDrawer
      ? (windowWidthSize - 64) / 2 - 32
      : windowWidthSize - 128
    const containerWidth =
      width / displaySizeChart -
      (16 * (displaySizeChart - 1)) / displaySizeChart

    return containerWidth
  }, [displaySizeChart, isOpenDrawer, windowWidthSize])

  const containerChartHeight = React.useMemo(
    () => containerChartWidth / 1.58,
    [containerChartWidth],
  )

  const isNotWordmap = React.useMemo(
    () =>
      !dataChartMore.data.length &&
      !dataChart.dataTimeLine.data.length &&
      !dataChart.dataListWordCloud.data.length &&
      !dataChart.wordLesChart.dataEchart.length &&
      !dataChart.wordLesChart.dataWordCloud.length,
    [
      dataChartMore.data.length,
      dataChart.dataTimeLine.data.length,
      dataChart.dataListWordCloud.data.length,
      dataChart.wordLesChart.dataEchart.length,
      dataChart.wordLesChart.dataWordCloud.length,
    ],
  )

  const fileType = useMemo(() => {
    switch (values.analysis_menu) {
      case '0':
        if (!typeNews) return 'file_text'
        return 'file_path'
      case '1':
        return 'company_industry'
      case '2':
        return 'company_year'
      default:
        return 'file_path'
    }
  }, [typeNews, values.analysis_menu])

  const payloadSendWebsocket = useMemo(() => {
    let payloadMoreChart = dataChartMore.data.map(item => ({
      [item.title]: item.words_cloud,
    }))

    const result = payloadMoreChart.reduce((acc, cur) => {
      return { ...acc, ...cur }
    }, {})

    switch (values.analysis_menu) {
      case '0':
        return responseDataWordCloud.option1

      case '1':
        return {
          ...responseDataWordCloud.option2,
          words_cloud: {
            ...responseDataWordCloud.option2?.words_cloud,
            ...result,
          },
        }

      case '2':
        return responseDataWordCloud.option3

      default:
        return {}
    }
  }, [
    dataChartMore.data,
    values.analysis_menu,
    responseDataWordCloud.option1,
    responseDataWordCloud.option2,
    responseDataWordCloud.option3,
  ])

  const fetchDataChartNewsString = useCallback(async (id: string) => {
    try {
      const { data, hashed_data } = await newServices.getDataChartNewsString(
        +id,
      )

      setDataChart(prev => ({
        ...prev,
        wordLesChart: {
          dataWordCloud: data?.word_cloud ?? [],
          dataEchart: data?.les_miserable ?? [],
          hashed_data: hashed_data ?? '',
        },
        dataListWordCloud: {
          data: [],
          hashed_data: '',
        },
        dataTimeLine: {
          data: [],
          hashed_data: '',
        },
        loading: false,
      }))

      setResponseDataWordCloud(prev => ({ ...prev, option1: data }))
    } catch (error) {
      setDataChart(prev => ({
        ...prev,
        loading: false,
        wordLesChart: {
          dataEchart: [],
          dataWordCloud: [],
          hashed_data: '',
        },
        dataListWordCloud: {
          data: [],
          hashed_data: '',
        },
        dataTimeLine: {
          data: [],
          hashed_data: '',
        },
      }))
    }
  }, [])

  const fetchWordCategoryChart = useCallback(
    async (id: string, year: number) => {
      try {
        const { data, hashed_data } = await newServices.getWordCategoryChart({
          year: year,
          news_id: +id,
          quarter: quarter,
          type_of_process: reportType,
          ngram: +values.number_of_word,
        })

        const convertData = Object.entries(data.words_cloud)
          .filter(([, words]) => words.length)
          .map(([key, words]) => ({ title: key, words_cloud: words }))

        const arrCompany = data.companies_ability_to_compare.map(o => ({
          label: Object.keys(o)[0],
          value: o[Object.keys(o)[0]],
        }))

        setMultiCompanyOptions(arrCompany)
        setDataChart(prev => ({
          ...prev,
          wordLesChart: {
            dataWordCloud: [],
            dataEchart: [],
            hashed_data: '',
          },
          dataListWordCloud: {
            data: convertData,
            hashed_data: hashed_data ?? '',
          },
          dataTimeLine: {
            data: [],
            hashed_data: '',
          },
          loading: false,
        }))

        setResponseDataWordCloud(prev => ({ ...prev, option2: data }))
      } catch (error) {
        setDataChart(prev => ({
          ...prev,
          loading: false,
          wordLesChart: {
            dataEchart: [],
            dataWordCloud: [],
            hashed_data: '',
          },
          dataListWordCloud: {
            data: [],
            hashed_data: '',
          },
          dataTimeLine: {
            data: [],
            hashed_data: '',
          },
        }))
      }
    },
    [quarter, reportType, values.number_of_word],
  )

  const fetchWordCloudTimeLineChart = useCallback(
    async (id: string) => {
      try {
        const { data: data3, hashed_data: hashed_data_3 } =
          await newServices.getWordCloudTimeLineChart({
            year: year,
            news_id: +id,
            quarter: props.quarter,
            type_of_process: reportType,
            previous_times: +values.term,
            ngram: +values.number_of_word,
          })

        const convertData = data3.sort(
          (a: { index: number }, b: { index: number }) => {
            if (values.sort_by === 1) {
              return a.index - b.index
            } else {
              return b.index - a.index
            }
          },
        )

        setDataChart(prev => ({
          ...prev,
          wordLesChart: {
            dataWordCloud: [],
            dataEchart: [],
            hashed_data: '',
          },
          dataListWordCloud: {
            data: [],
            hashed_data: '',
          },
          dataTimeLine: {
            data: convertData,
            hashed_data: hashed_data_3 ?? '',
          },
          loading: false,
        }))

        setResponseDataWordCloud(prev => ({ ...prev, option3: data3 }))
      } catch (error) {
        setDataChart(prev => ({
          ...prev,
          loading: false,
          wordLesChart: {
            dataEchart: [],
            dataWordCloud: [],
            hashed_data: '',
          },
          dataListWordCloud: {
            data: [],
            hashed_data: '',
          },
          dataTimeLine: {
            data: [],
            hashed_data: '',
          },
        }))
      }
    },
    [
      year,
      reportType,
      values.term,
      props.quarter,
      values.sort_by,
      values.number_of_word,
    ],
  )

  // fetchDataChart with news is string
  const fetchDataChart = useCallback(async () => {
    if (!id) return

    setDataChart(prev => ({
      ...prev,
      loading: true,
      wordLesChart: {
        dataEchart: [],
        dataWordCloud: [],
        hashed_data: '',
      },
      dataListWordCloud: {
        data: [],
        hashed_data: '',
      },
      dataTimeLine: {
        data: [],
        hashed_data: '',
      },
    }))

    switch (values.analysis_menu) {
      case '0':
        fetchDataChartNewsString(id)
        break
      case '1':
        if (!year) return
        fetchWordCategoryChart(id, year)
        break
      case '2':
        if (!values.term) {
          setDataChart(prev => ({
            ...prev,
            loading: false,
            wordLesChart: {
              dataEchart: [],
              dataWordCloud: [],
              hashed_data: '',
            },
            dataListWordCloud: {
              data: [],
              hashed_data: '',
            },
            dataTimeLine: {
              data: [],
              hashed_data: '',
            },
          }))
          break
        }
        fetchWordCloudTimeLineChart(id)
        break
      default:
        break
    }
  }, [
    id,
    year,
    values.term,
    values.analysis_menu,
    fetchWordCategoryChart,
    fetchDataChartNewsString,
    fetchWordCloudTimeLineChart,
  ])

  const fetchMoreWordCloud = useCallback(async () => {
    if (!id || !values.multi_company || !year) return

    try {
      setDataChartMore(prev => ({
        ...prev,
        loading: true,
        data: [],
        hashed_data: '',
      }))

      const arrIdCompany = values.multi_company.map(obj => obj.value)

      const arrCompany = await Promise.all(
        arrIdCompany.map(
          async item =>
            await newServices.getWordCloudMoreChart({
              year: year,
              news_id: +id,
              quarter: quarter,
              compare_to: +item,
              type_of_process: reportType,
              ngram: +values.number_of_word,
            }),
        ),
      )

      let newData: {
        title: string
        words_cloud: { text: string; value: number }[]
      }[] = []
      let hashed_data = ''

      arrCompany.forEach(item => {
        let title = Object.keys(item.data)[0]

        let data = item.data[title].map(word => ({
          text: word.text,
          value: word.value,
        }))

        hashed_data = hashed_data + item.hashed_data
        newData = [...newData, { title: title, words_cloud: data }]
      })

      setDataChartMore(prev => ({
        ...prev,
        data: newData,
        loading: false,
        hashed_data: hashed_data,
      }))
    } catch (error) {
      setDataChartMore(prev => ({
        ...prev,
        loading: false,
        data: [],
        hashed_data: '',
      }))
    }
  }, [
    id,
    year,
    quarter,
    reportType,
    values.multi_company,
    values.number_of_word,
  ])

  const handleOffSwitch = () => {
    setValueSwitch(false)
    setValueExplanation(prev => ({
      ...prev,
      loading: false,
      data: '',
      done: true,
    }))

    abortControllerRef.current.abort()
    abortControllerRef.current = new AbortController()
  }

  const handleResetForm = (
    e:
      | {
          label: string
          value: string | number
        }
      | undefined,
  ) => {
    if (e?.value === values.analysis_menu) return

    setValues({
      term: '',
      sort_by: 1,
      quarters: '',
      targetYear: '',
      display_size: '0',
      analysis_menu: String(e?.value),
      multi_company: [],
      number_of_word: '2',
    })
    handleResetDataChart()
    handleOffSwitch()
  }

  const handleSwitch = async () => {
    if (!companyName || isNotWordmap) return

    if (valueSwitch) {
      handleOffSwitch()
    } else {
      if (valueExplanation.loading) return

      if (values.analysis_menu === '2' && !values.term) return

      if (isNotWordmap) return

      setValueSwitch(true)
      setReloadChart(!reloadChart)
      setValueExplanation(prev => ({
        ...prev,
        loading: true,
        data: '',
        done: false,
      }))
    }
  }

  const handleResetDataChart = () => {
    setDataChart(prev => ({
      ...prev,
      wordLesChart: {
        dataEchart: [],
        dataWordCloud: [],
        hashed_data: '',
      },
      dataListWordCloud: {
        data: [],
        hashed_data: '',
      },
      dataTimeLine: {
        data: [],
        hashed_data: '',
      },
      loading: false,
    }))
    setDataChartMore(prev => ({
      ...prev,
      data: [],
      loading: false,
      hashed_data: '',
    }))
  }

  const handleOpenZoom = (info: {
    isShowAll: boolean
    typeChart: string
    index: number | null
  }) => {
    setInfoModal(info)
    onOpenZoom()
  }

  const handleCloseZoom = () => {
    onCloseZoom()
  }

  useEffect(() => {
    fetchMoreWordCloud()
  }, [fetchMoreWordCloud])

  useEffect(() => {
    fetchDataChart()
  }, [fetchDataChart])

  const hashedDataChart = useMemo(() => {
    switch (values.analysis_menu) {
      case '0':
        return dataChart.wordLesChart.hashed_data

      case '1':
        return (
          dataChart.dataListWordCloud.hashed_data + dataChartMore.hashed_data
        )

      case '2':
        return dataChart.dataTimeLine.hashed_data

      default:
        return ''
    }
  }, [
    values.analysis_menu,
    dataChartMore.hashed_data,
    dataChart.dataTimeLine.hashed_data,
    dataChart.wordLesChart.hashed_data,
    dataChart.dataListWordCloud.hashed_data,
  ])

  useEffect(() => {
    if (!accessToken || isNotWordmap || !valueSwitch) return

    const newSocket = new WebSocket(
      `${process.env.REACT_APP_WEBSOCKET_URL}/api/v1/advisor/list_new_file/summary_image/ws_stream`,
    )

    const handleSocketOpen = async () => {
      console.log('WS connected')
      const messAuthen = {
        type: 'auth_req',
        data: { Authorization: `Bearer ${accessToken}` },
      }
      newSocket.send(JSON.stringify(messAuthen))

      let message = {
        type: 'stream_req',
        data: {
          data: payloadSendWebsocket,
          company: companyName,
          type_image: fileType,
          hashed_data: hashedDataChart,
          type_of_process: reportType,
        },
      }

      newSocket.send(JSON.stringify(message))
    }

    const handleSocketMessage = (event: { data: string }) => {
      const objMess = JSON.parse(event.data)

      if (objMess?.done === 1) {
        setValueExplanation(prev => ({
          ...prev,
          done: true,
          data: prev.data.replace(/```/g, ''),
        }))
      } else {
        switch (objMess.type) {
          case 'stream_res':
            setValueExplanation(prev => ({
              ...prev,
              loading: false,
              data: (prev.data + objMess?.data).replace(/```html/g, ''),
            }))
            break
          default:
            break
        }
      }
    }

    const handleSocketClose = () => {
      console.log('WebSocket is disconnected')
    }

    newSocket.addEventListener('open', handleSocketOpen)
    newSocket.addEventListener('close', handleSocketClose)
    newSocket.addEventListener('message', handleSocketMessage)

    return () => {
      newSocket.removeEventListener('open', handleSocketOpen)
      newSocket.removeEventListener('close', handleSocketClose)
      newSocket.removeEventListener('message', handleSocketMessage)
      newSocket.close()
    }
  }, [
    fileType,
    reportType,
    valueSwitch,
    accessToken,
    companyName,
    isNotWordmap,
    hashedDataChart,
    payloadSendWebsocket,
    values.analysis_menu,
  ])

  useEffect(() => {
    const handleResize = () => {
      setWindowWidthSize(document.documentElement.clientWidth)
    }

    window.addEventListener('resize', handleResize)

    handleResize()

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  useEffect(() => {
    if (isOpenDrawer) {
      setFieldValue('display_size', '0')
    } else {
      setFieldValue('display_size', '1')
    }
  }, [isOpenDrawer, setFieldValue])

  return {
    ...props,
    formik,
    isZoom,
    values,
    typeNews,
    dataChart,
    infoModal,
    reportType,
    valueSwitch,
    isNotWordmap,
    isOpenDrawer,
    dataChartMore,
    displaySizeChart,
    valueExplanation,
    containerChartWidth,
    multiCompanyOptions,
    optionsAnalysisMenu,
    containerChartHeight,
    handleSwitch,
    setDataChart,
    setFieldValue,
    handleOpenZoom,
    handleResetForm,
    handleOffSwitch,
    handleCloseZoom,
  }
}
