import { Flex, Text } from '@chakra-ui/react'
import { Button } from 'components/fromAdvisor/elements'
import { Switch } from 'components/fromAdvisor/elements/Switch'
import React, { FC } from 'react'

type AiExplainAndZoomAllProps = {
  valueSwitch: boolean
  isNotWordmap: boolean
  handleSwitch: () => Promise<void>
  handleOpenZoom: (info: {
    isShowAll: boolean
    typeChart: string
    index: number | null
  }) => void
}

const AiExplainAndZoomAll: FC<AiExplainAndZoomAllProps> = ({
  valueSwitch,
  isNotWordmap,
  handleSwitch,
  handleOpenZoom,
}) => {
  return (
    <Flex align={'center'} gap={'6px'} w={'204px'}>
      <Text
        fontSize="14px"
        fontWeight="normal"
        lineHeight="14px"
        minW="fit-content"
      >
        AI解説
      </Text>
      <Switch
        cusbgcolor={'#0084FF'}
        isChecked={valueSwitch}
        onChange={() => handleSwitch()}
      />
      <Button
        _hover={{
          bgColor: 'white.50',
        }}
        bgColor="white.50"
        border="solid 1px"
        borderColor="blue.900"
        borderRadius="12px"
        color="blue.900"
        fontSize="sm"
        fontWeight="bold"
        h="40px"
        isDisabled={isNotWordmap}
        lineHeight="shorter"
        padding="8px 16px"
        text="全画面表示"
        onClick={() => {
          handleOpenZoom({ isShowAll: true, typeChart: '', index: null })
        }}
      />
    </Flex>
  )
}

export default AiExplainAndZoomAll
