import { FormikErrors } from 'formik'
import { useCallback, useEffect, useState } from 'react'
import { companyServices } from 'services'
import { Option } from 'types/conmon'
import { FormValues } from '../hook'

export type RenderActionOpenDrawerProps = {
  year?: number
  values: FormValues
  quarter: number
  companyId?: number
  reportType: number
  handleOffSwitch: () => void
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined,
  ) => Promise<void> | Promise<FormikErrors<FormValues>>
}

export const useRenderActionOpenDrawer = (
  props: RenderActionOpenDrawerProps,
) => {
  const { year, quarter, companyId, reportType } = props
  const [termOptions, setTermOptions] = useState<Option[]>([])

  const fetchReportYearQuarters = useCallback(async () => {
    try {
      if (!companyId) return

      const { data } = await companyServices.getReportYearQuarters({
        company_id: +companyId,
        type_of_process: reportType,
      })

      let term: Option[] = []
      let count = 0

      if (!['2', '5'].includes(String(reportType))) {
        for (const value in data) {
          if (Number(value) <= Number(year) && data[value].includes(+quarter)) {
            count += 1
          }
          if (count === 5) break
        }
      } else {
        for (const value in data) {
          if (Number(value) <= Number(year)) {
            count += 1
          }
          if (count === 5) break
        }
      }

      term = Array.from(Array(count).keys()).map(o => ({
        label: `${o + 1}期分`,
        value: o + 1,
      }))

      setTermOptions(term)
    } catch (error) {
      console.log('error', error)
    }
  }, [companyId, quarter, reportType, year])

  useEffect(() => {
    fetchReportYearQuarters()
  }, [fetchReportYearQuarters])

  return { ...props, termOptions }
}
