/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box, Flex, Text } from '@chakra-ui/react'
import { SelectField } from 'components/atoms'
import React, { FC } from 'react'
import { FormikProvider } from 'formik'
import { Button } from 'components/fromAdvisor/elements'
import { ContentAskAI } from '../ContentAskAI'
import ModalAIAdvice from '../ModalAIAdvice'
import ModalConfirmAIAdvice from '../ModalConfirmAIAdvice'
import { AIAdviceCompanyProps, useAIAdviceCompany } from './hook'

/**
 * AIAdvice molecules
 * @constructor
 */
export const AIAdviceCompany: FC<AIAdviceCompanyProps> = props => {
  const {
    formik,
    values,
    AIAnswer,
    isValues,
    expanded,
    isDisableBtn,
    valueSelected,
    isToggleButton,
    conversationId,
    showConfirmModal,
    optionPromptCompany,
    setAIAnswer,
    setExpanded,
    toggleExpanded,
    setValueSelected,
    handleCloseModal,
    handleSendChatAi,
    setConversationId,
    setShowConfirmModal,
    disconnectWebSocket,
  } = useAIAdviceCompany(props)

  return (
    <FormikProvider value={formik}>
      <Box
        bg="white.base"
        borderRadius={4}
        boxSizing="border-box"
        padding="12px 16px 16px 16px"
        w="50%"
      >
        <Flex
          alignItems="center"
          justifyContent="space-between"
          mb="12px"
          w="100%"
        >
          <Flex alignItems="center" gap="22px" w="100%">
            <Text
              color="black.100"
              fontSize="lg"
              fontWeight="bold"
              letterSpacing="-0.02em"
              lineHeight="tall"
              minW="fit-content"
              p="0px 8px"
            >
              AIアドバイス
            </Text>
            <SelectField
              heightListOptions="220px"
              isDisabled={AIAnswer.loading}
              maxWidth="full"
              minHeight="40px"
              name="askAI"
              options={optionPromptCompany}
              placeholder="当社の最新の経営・財務・営業戦略は？ "
              valueOption={optionPromptCompany.find(
                it => it.value === valueSelected,
              )}
              onChange={e => {
                if (values.askAI !== e?.value) {
                  setAIAnswer(prev => ({
                    ...prev,
                    data: '',
                    loading: false,
                    done: true,
                  }))
                  setConversationId(null)
                  setValueSelected(Number(e?.value))
                }
              }}
            />
            {isToggleButton ? (
              <Button
                bgColor={'white'}
                border={'1px solid #0084ff'}
                color="blue.900"
                fontSize={'16px'}
                fontWeight={'bold'}
                h={'40px'}
                letterSpacing={'-0.02px'}
                lineHeight={'shorter'}
                minW={'122px'}
                px={5}
                text="キャンセル"
                onClick={disconnectWebSocket}
              />
            ) : (
              <Button
                bgColor={'blue.900'}
                border={'none'}
                color="white.50"
                fontSize={'16px'}
                fontWeight={'bold'}
                h={'40px'}
                isDisabled={isDisableBtn}
                letterSpacing={'-0.02px'}
                lineHeight={'shorter'}
                minW={'122px'}
                px={5}
                text="実行"
                onClick={handleSendChatAi}
              />
            )}
          </Flex>
        </Flex>
        <ContentAskAI
          content={AIAnswer}
          heightContent="144px"
          isCopyBtn={true}
          isDone={AIAnswer.done}
          isHidden={false}
          isOpenModal={expanded}
          isValues={isValues}
          minHeight={'192px'}
          toggleExpanded={toggleExpanded}
        />
      </Box>
      <ModalAIAdvice
        AIAnswer={AIAnswer}
        askAIOptions={optionPromptCompany}
        conversationId={conversationId}
        isDisableBtn={isDisableBtn}
        isOpen={expanded}
        isToggleButton={isToggleButton}
        isValues={isValues}
        setAIAnswer={setAIAnswer}
        setConversationId={setConversationId}
        setExpanded={setExpanded}
        setShowConfirmModal={setShowConfirmModal}
        setValueAskAI={setValueSelected}
        value={values.askAI}
        onCancelChatAi={disconnectWebSocket}
        onClose={handleCloseModal}
        onSubmit={handleSendChatAi}
      />
      <ModalConfirmAIAdvice
        conversationId={conversationId}
        isOpen={showConfirmModal}
        setExpanded={setExpanded}
        setShowConfirmModal={setShowConfirmModal}
      />
    </FormikProvider>
  )
}
