import { Box, Center, Flex, Text, Tooltip } from '@chakra-ui/react'
import React, { FC, useMemo, useState } from 'react'
import { Button, TextOverflowTooltip } from 'components/fromAdvisor/elements'
import { Option } from 'types/conmon'
import { CloseBlueSmallIcon } from 'components/fromAdvisor/elements/Icons'
import { ArrowDown } from '../Icons'
import { StyledCheckbox } from '../CheckboxField'
import { InputField } from '../InputField'

type CustomMultiSelectProps = {
  options: Option[]
  placeholder: string
  defaultValue: Option[]
  setValue: (options: Option[]) => void
  labelTooltip: string
  labelEmptyOption: string
}

const MAX_COMPANY_COMPARE = 2

const CustomMultiSelect: FC<CustomMultiSelectProps> = ({
  options,
  placeholder,
  defaultValue,
  setValue,
  labelTooltip,
  labelEmptyOption,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [search, setSearch] = useState('')
  const [selectedOptions, setSelectedOptions] = useState<Option[]>(defaultValue)

  const isDisabled = (option: Option) => {
    if (!isMaxValue) return false
    return !selectedOptions.map(item => item.value).includes(option.value)
  }

  const isSelected = (option: Option) =>
    selectedOptions.map(item => item.value).includes(option.value)

  const isMaxValue = React.useMemo(
    () => selectedOptions.length >= MAX_COMPANY_COMPARE,
    [selectedOptions.length],
  )

  const optionFilter = useMemo(() => {
    if (!search) return options
    return options.filter(option => option.label.includes(search.trim()))
  }, [options, search])

  const handleOpen = () => {
    setIsOpen(true)
  }

  const handleCancel = () => {
    setIsOpen(false)
    setSearch('')
  }

  const handleRemoveTag = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    id: string | number,
  ) => {
    e.stopPropagation()
    let newValue = defaultValue.filter(it => it.value !== id)
    setValue(newValue)
    setSelectedOptions(newValue)
  }

  const handleOptionClick = (option: Option) => {
    const exsistOption = selectedOptions.find(opt => opt.value === option.value)
    if (exsistOption !== undefined) {
      const newValue = selectedOptions.filter(opt => opt.value !== option.value)
      setSelectedOptions(newValue)
    } else {
      if (selectedOptions.length >= MAX_COMPANY_COMPARE) return
      setSelectedOptions(prev => [...prev, option])
    }
  }

  const handleChangeMultiValue = () => {
    setValue(selectedOptions)
    handleCancel()
  }

  return (
    <Box maxW={'100%'} minH={10} minW={'150px'} position={'relative'}>
      <Tooltip
        hasArrow
        bgColor={'#E5F2FE'}
        borderRadius={'24px'}
        color="blue.900"
        fontSize={'14px'}
        h={'30px'}
        label={
          options.length === 0
            ? labelEmptyOption
            : defaultValue.length
            ? ''
            : labelTooltip
        }
        lineHeight={'26px'}
        placement="top"
        px={4}
      >
        <Flex
          alignItems={'center'}
          backgroundColor={options.length === 0 ? '#E8ECEF' : '#FEFEFE'}
          borderColor={'#E8ECEF'}
          borderRadius={'12px'}
          borderStyle={'solid'}
          borderWidth={'1px'}
          boxShadow="0px 5px 10px -5px #1417181A inset"
          cursor={options.length === 0 ? 'not-allowed' : 'pointer'}
          gap={3}
          justifyContent={'space-between'}
          minH={10}
          paddingLeft={4}
          pr={1}
          py={2}
          w={'100%'}
          onClick={() => {
            if (options.length === 0) return
            handleOpen()
          }}
        >
          <Flex gap={1} w={'calc(100% - 36px)'}>
            {defaultValue.length > 0 &&
              defaultValue.map(it => (
                <Flex
                  key={it.value}
                  alignItems={'center'}
                  bgColor={'blue.900'}
                  borderRadius={'4px'}
                  gap={1}
                  justifyContent={'center'}
                  maxW={'100%'}
                  px={'4px'}
                >
                  <Text color={'white'} fontSize="14px" lineHeight={'24px'}>
                    {it.label}
                  </Text>
                  <Flex
                    alignItems={'center'}
                    bgColor={'white'}
                    borderRadius={'12px'}
                    h={'24px'}
                    justifyContent={'center'}
                    minW={'24px'}
                    transform={'scale(0.7)'}
                    onClick={e => handleRemoveTag(e, it.value)}
                  >
                    <CloseBlueSmallIcon />
                  </Flex>
                </Flex>
              ))}
            {defaultValue.length === 0 && (
              <Text
                color={'#141718'}
                fontSize={'14px'}
                lineHeight={'24px'}
                opacity={0.3}
                overflow={'hidden'}
                textOverflow={'ellipsis'}
                whiteSpace={'nowrap'}
                width={'256px'}
              >
                {placeholder}
              </Text>
            )}
          </Flex>
          <ArrowDown fontSize="24px" />
        </Flex>
      </Tooltip>
      {isOpen && (
        <Box
          backgroundColor={'#FEFEFE'}
          borderRadius={'4px'}
          boxShadow="0px 33px 24px -17px #00000014, 0px 0px 25px 4px #00000008"
          left={-5.5}
          position={'absolute'}
          px={8}
          py={6}
          top={11.5}
          w={'344px'}
          zIndex={1000}
        >
          <Text
            color={'black.100'}
            fontSize={'14px'}
            fontWeight={'bold'}
            lineHeight={'24px'}
          >
            会社名
          </Text>
          <InputField
            isInForm={false}
            name="search"
            placeholder={placeholder}
            type="text"
            value={search}
            width="100%"
            onChange={e => {
              setSearch(e.target.value)
            }}
          />
          {isMaxValue && (
            <Text
              color={'blue.900'}
              fontSize={'14px'}
              lineHeight={'24px'}
              mb={3}
            >
              {labelTooltip}
            </Text>
          )}
          <Flex
            backgroundColor="#F3F5F7"
            borderRadius={'12px'}
            direction={'column'}
            gap={4}
            maxHeight="300px"
            mt={3}
            overflowY="auto"
            p={3}
          >
            {optionFilter.length ? (
              optionFilter.map((option, index) => (
                <Flex
                  key={index}
                  alignItems={'center'}
                  gap={2}
                  style={{
                    cursor: 'pointer',
                    backgroundColor: 'transparent',
                  }}
                >
                  <StyledCheckbox
                    isChecked={isSelected(option)}
                    isDisabled={isDisabled(option)}
                    value={option.value}
                    onChange={() => handleOptionClick(option)}
                  />
                  {isDisabled(option) ? (
                    <Text
                      color={'#141718'}
                      cursor={'not-allowed'}
                      fontSize={'14px'}
                      lineHeight={'24px'}
                      opacity={0.3}
                      overflow={'hidden'}
                      textOverflow={'ellipsis'}
                      whiteSpace={'nowrap'}
                      width={'209px'}
                    >
                      {option.label}
                    </Text>
                  ) : (
                    <TextOverflowTooltip
                      color={'#141718'}
                      fontSize="14px"
                      isCursor={!isDisabled(option)}
                      opacity={isDisabled(option) ? '0.3' : '1'}
                      value={option.label}
                      onClick={() => handleOptionClick(option)}
                    />
                  )}
                </Flex>
              ))
            ) : (
              <Center>
                <Text color={'#141718'} fontSize={'14px'} lineHeight={'24px'}>
                  {labelEmptyOption}
                </Text>
              </Center>
            )}
          </Flex>
          <Flex direction={'column'} gap={4} mt={6}>
            <Button
              backgroundColor={'blue.900'}
              border={'1px solid'}
              borderColor={'blue.900'}
              color="#FEFEFE"
              text="決定"
              w={'full'}
              onClick={handleChangeMultiValue}
            />
            <Button
              backgroundColor={'#FEFEFE'}
              border={'1px solid'}
              borderColor={'blue.900'}
              color="blue.900"
              text="とじる"
              w={'full'}
              onClick={() => {
                setSelectedOptions(defaultValue)
                handleCancel()
              }}
            />
          </Flex>
        </Box>
      )}
    </Box>
  )
}

export default CustomMultiSelect
