import { axiosWithFile, axiosWithToken } from 'libs/configureAxios'
import { BasicResponse } from 'types/conmon'
import {
  Body,
  HistoriesResponse,
  HistoryConverted,
  ParamSearchConversation,
  ConversationResponse,
  ConversationConverted,
  ListConversationConverted,
  BodyChangeBookmarked,
  ListConversationResponse,
  BodyUploadUrl,
  ParamsGetConsultation,
  ConsultationResponse,
  CategoriesConverted,
  CategoriesResponse,
  RulesConverted,
  RulesResponse,
  OptionCategoryResponse,
  BodySaveFavorite,
  ParamHistorySearch,
  DetailHistorySearchResponse,
  HistorySearchResponse,
  GetDataTalkStoryRequest,
  GetPromptNewsDetailResponse,
  GetPromptCompanyDetailResponse,
} from './chatAIService.type'
import { Categories } from './mockData'

/**
 * @returns function that handle chatAI services
 */
export class ChatAIService {
  // history services

  /**
   * @returns function that handle convert history detail response
   */
  convertedHistoryDetailResponse(
    response: HistoriesResponse,
  ): HistoryConverted {
    return {
      updatedAt: response.updated_at,
      questionHtml: response.question_html,
      questionText: response.question_text,
      answerData: response.answer_data,
      bookmarked: response.bookmarked,
      title: response.title,
      createdAt: response.created_at,
      id: response.id,
      userId: response.user_id,
      rating: response.rating,
      conversationId: response.ai_conversation_id,
      is_favorite: response.is_favorite,
      deleted_at: response.deleted_at,
      save_answer_favorite_id: response.save_answer_favorite_id,
    }
  }

  /**
   * @returns function that handle edit history service
   */
  async editHistory(id: number, body: Body) {
    return await axiosWithToken.put(
      `/v1/advisor/ai_conversation_history/${id}`,
      body,
    )
  }

  /**
   * @returns function that handle change bookmarked conversation service
   */
  changeBookmarkedConversation = async (
    id: number,
    body: BodyChangeBookmarked,
  ) =>
    await axiosWithToken.patch(
      `/v1/advisor/ai_conversation/${id}/bookmark`,
      body,
    )

  /**
   * @returns function that handle delete conversation service
   */
  deleteConversation = async (id: number) =>
    await axiosWithToken.delete(`/v1/advisor/ai_conversation/${id}`)

  /**
   * @returns function that handle convert conversation service
   */
  convertConversation(response: ConversationResponse): ConversationConverted {
    return {
      chatHistories:
        response.ai_conversation_histories &&
        response.ai_conversation_histories.map(
          this.convertedHistoryDetailResponse,
        ),
      conversationId: response.id,
      isOverMaxTimesChat: response.is_over_max_times_chat,
      updatedAt: response.updated_at,
      createdAt: response.created_at,
      userId: response.user_id,
      bookmarked: response.bookmarked,
      title: response.title,
    }
  }

  /**
   * @returns function that handle convert list conversation service
   */
  convertConversations(
    response: ListConversationResponse,
  ): ListConversationConverted {
    const { paginate, data } = response
    return {
      paginate: {
        page: paginate.page,
        pageCount: paginate.page_count,
        pageSize: paginate.page_size,
        totalCount: paginate.total_count,
      },
      data: data,
    }
  }

  /**
   * @returns function that handle get options category service
   */
  getOptionCategory = async (): Promise<OptionCategoryResponse> => {
    return (await axiosWithToken.get('/v1/advisor/consultations/category')).data
  }

  /**
   * @returns function that handle get list conversation service
   */
  getConversations = async (
    params: ParamSearchConversation,
  ): Promise<ListConversationConverted> => {
    return this.convertConversations(
      (
        await axiosWithToken.get('/v1/advisor/ai_conversation/', {
          params: params ?? {},
        })
      ).data,
    )
  }

  /**
   * @returns function that handle get conversation service
   */
  getConversationDetail = async (
    conversationId: number,
  ): Promise<ConversationConverted> => {
    return this.convertConversation(
      (
        await axiosWithToken.get(
          `/v1/advisor/ai_conversation/${conversationId}`,
        )
      ).data.data,
    )
  }

  /**
   * @returns function that handle get consultation list service
   */
  getConsultationList = async (
    params?: ParamsGetConsultation,
  ): Promise<ConsultationResponse> => {
    return (await axiosWithToken.get('/v1/advisor/consultations/', { params }))
      .data
  }

  /**
   * @returns function that handle get consultation list service
   */
  handleHistorySearch = async (
    body: ParamHistorySearch,
  ): Promise<DetailHistorySearchResponse> => {
    return (await axiosWithToken.post('/v1/advisor/search_logs/', body)).data
  }

  /**
   * @returns function that handle get history search list service
   */
  getHistorySearchList = async (): Promise<HistorySearchResponse> => {
    return (await axiosWithToken.get('/v1/advisor/search_logs/')).data
  }

  /**
   * @returns function that handle change favorite consultation service
   */
  async toggleFavorite(id: number, body: Body) {
    return await axiosWithToken.put(
      `/v1/advisor/consultations/${id}/toggle_favorite_status`,
      body,
    )
  }

  /**
   * @returns function that handle upload url
   */
  uploadUrl = async (body: BodyUploadUrl) =>
    (await axiosWithToken.post('/v1/advisor/chat_with_ai/attachment/url', body))
      .data

  /**
   * @returns function that handle upload file
   */
  uploadFile = async (body: FormData) =>
    await axiosWithFile.post('/v1/advisor/chat_with_ai/attachment/file', body)

  // send message services

  /**
   * @returns function that handle rating service
   */
  async rating(historyId: number, body: { rating: number }) {
    return await axiosWithToken.patch(
      `/v1/advisor/ai_conversation_history/${historyId}/rating`,
      body,
    )
  }

  /**
   * @returns function that handle convert response list category
   */
  convertCategoriesResponse(
    response: CategoriesResponse[],
  ): CategoriesConverted[] {
    return response.map(
      ({ updated_at, ja_name, id, created_at, code, themes }) => ({
        updatedAt: updated_at,
        code,
        createdAt: created_at,
        id,
        jaName: ja_name,
        themes: themes.map(
          ({ updated_at, ja_name, code, id, created_at, category_id }) => ({
            updatedAt: updated_at,
            jaName: ja_name,
            code,
            id,
            categoryId: category_id,
            createdAt: created_at,
          }),
        ),
      }),
    )
  }

  /**
   * @returns function that handle get category
   */
  async getCategories(): Promise<CategoriesConverted[]> {
    return this.convertCategoriesResponse(Categories)
  }

  /**
   * @returns function that handle convert settings rule
   */
  convertRulesResponse(response: RulesResponse | null): RulesConverted | null {
    if (!response) return null
    return {
      id: response.id,
      createdAt: response.created_at,
      updatedAt: response.updated_at,
      outputConditions: response.output_conditions,
      userId: response.user_id,
      constraints: response.constraints,
      automaticReplyMessage: response.automatic_reply_message,
      isUseAutomaticReply: response.is_use_automatic_reply,
    }
  }

  /**
   * @returns function that handle get rules
   */
  async getRules(): Promise<RulesConverted | null> {
    return this.convertRulesResponse(null)
  }

  /**
   * @returns function that handle settings rules
   */
  async sendSettingRules() {
    return new Promise(resolve => {
      resolve('successfully')
    })
  }

  /**
   * @returns function that handle save create prompt service
   */
  async saveFavorite(body: BodySaveFavorite) {
    return await axiosWithToken.post('/v1/advisor/save_answer_favorite/', body)
  }

  /**
   * @returns function that get options industry of talk story card
   */
  async getOptionsIndustry(): Promise<
    BasicResponse<
      {
        id: number
        name: string
      }[]
    >
  > {
    return (await axiosWithToken.get('/v1/common/settings/company/categories'))
      .data
  }

  /**
   * @returns function that get options story of talk story card
   */
  async getOptionsStory(): Promise<
    BasicResponse<
      {
        id: number
        kind: number
        title: string
        content?: string
      }[]
    >
  > {
    return (
      await axiosWithToken.get('/v1/advisor/news/accelerated_prompts/', {
        params: { kind: 1 },
      })
    ).data
  }

  /**
   * @returns function that get data of talk story card
   */
  async getDataTalkStory(
    params: GetDataTalkStoryRequest,
  ): Promise<BasicResponse<string>> {
    return (
      await axiosWithToken.post('/v1/advisor/news/ai_recommend/', null, {
        params,
      })
    ).data
  }

  /**
   * @returns function that get news prompt detail
   */
  async getPromptNewsDetail(
    params: {
      prompt_id: number
      news_id: number
    },
    signal: AbortController,
  ): Promise<BasicResponse<GetPromptNewsDetailResponse>> {
    return (
      await axiosWithToken.get(`/v1/advisor/prompt/prompt_new_detail`, {
        params: { prompt_id: params.prompt_id, news_id: params.news_id },
        signal: signal.signal,
      })
    ).data
  }

  /**
   * @returns function that get company prompt detail
   */
  async getPromptCompanyDetail(
    params: {
      prompt_id: number
      company_id: number
    },
    signal: AbortController,
  ): Promise<BasicResponse<GetPromptCompanyDetailResponse>> {
    return (
      await axiosWithToken.get(`/v1/advisor/prompt/prompt_company_detail`, {
        params: { prompt_id: params.prompt_id, company_id: params.company_id },
        signal: signal.signal,
      })
    ).data
  }

  /**
   * @returns function that get company prompt detail
   */
  async getUrlIframe(): Promise<{ app_url: string }> {
    return (await axiosWithToken.get(`/v1/advisor/search_agent/app_info`)).data
  }

  /**
   * @returns function that disable modal milize agent
   */
  async putDisableSearchAgentGuide(): Promise<{
    is_success: boolean
    message: string
  }> {
    return (
      await axiosWithToken.put(
        `/v1/advisor/profile/me/disable_search_agent_guide`,
      )
    ).data
  }

  /**
   * @returns function that enable modal milize agent
   */
  async putEnableSearchAgentGuide(): Promise<{
    is_success: boolean
    message: string
  }> {
    return (
      await axiosWithToken.put(
        `/v1/advisor/profile/me/enable_search_agent_guide`,
      )
    ).data
  }
}
