import { Box, Flex, Text } from '@chakra-ui/react'
import { SelectField } from 'components/atoms'
import React, { FC } from 'react'
import { optionAnalysisMenu, optionTargetDocument } from '../data'
import { FieldWordMapWrapperProps, useFieldWordMapWrapper } from './hook'

const FieldWordMapWrapper: FC<FieldWordMapWrapperProps> = props => {
  const {
    setFieldValue,
    setValues,
    values,
    handleResetForm,
    setYearQuaters,
    handleResetDataChart,
    handleOffSwitch,
    reportYearsOptions,
    reportQuartersOptions,
    termOptions,
  } = useFieldWordMapWrapper(props)

  return (
    <Flex align={'center'} h={'full'}>
      <Box mr={'16px'}>
        <SelectField
          height="40px"
          heightListOptions={'176px'}
          isInForm={false}
          menuPlacement="bottom"
          name="analysis_menu"
          options={optionAnalysisMenu}
          placeholder=""
          valueOption={optionAnalysisMenu.find(
            it => it.value === values.analysis_menu,
          )}
          width="237px"
          onChange={e => handleResetForm(e)}
        />
      </Box>
      <Flex
        align={'center'}
        borderLeft={'1px solid #E8ECEF'}
        gap={'4px'}
        h={'full'}
        px="12px"
      >
        <Flex align={'center'} gap={1}>
          <SelectField
            height="40px"
            heightListOptions={'176px'}
            isInForm={false}
            menuPlacement="bottom"
            name="targetDocument"
            options={optionTargetDocument}
            placeholder="資料"
            valueOption={optionTargetDocument.find(
              it => it.value === values.targetDocument,
            )}
            width="213px"
            onChange={e => {
              if (values.targetDocument === e?.value) return
              setValues({
                ...values,
                targetDocument: String(e?.value) ?? '',
                targetYear: '',
                quarters: '',
                term: '',
                multi_company: [],
              })
              setYearQuaters({})
              handleResetDataChart()
            }}
          />
          <SelectField
            height="40px"
            heightListOptions={'176px'}
            isInForm={false}
            menuPlacement="bottom"
            name="targetYear"
            options={reportYearsOptions}
            placeholder="年度"
            valueOption={reportYearsOptions.find(
              it => it.value === values.targetYear,
            )}
            width="125px"
            onChange={e => {
              if (values.targetYear === e?.value) return
              setValues({
                ...values,
                targetYear: String(e?.value) ?? '',
                quarters: '',
                term: '',
                multi_company: [],
              })
              handleResetDataChart()
              handleOffSwitch()
            }}
          />
          {!['2', '5'].includes(values.targetDocument) && (
            <SelectField
              height="40px"
              heightListOptions={'176px'}
              isInForm={false}
              menuPlacement="bottom"
              name="quarters"
              options={reportQuartersOptions}
              placeholder="四半期"
              valueOption={reportQuartersOptions.find(
                it => it.value === values.quarters,
              )}
              width="130px"
              onChange={e => {
                if (values.quarters === e?.value) return
                setValues({
                  ...values,
                  quarters: String(e?.value) ?? '',
                  term: '',
                  multi_company: [],
                })
                handleResetDataChart()
                handleOffSwitch()
              }}
            />
          )}

          {values.analysis_menu === '2' && (
            <>
              <Text
                color="black.100"
                fontSize="14px"
                fontWeight="normal"
                lineHeight="shorter"
                minW="fit-content"
              >
                から過去
              </Text>
              <SelectField
                height="40px"
                heightListOptions={'176px'}
                isInForm={false}
                menuPlacement="bottom"
                name="term"
                options={termOptions}
                placeholder="期分"
                valueOption={termOptions.find(it => it.value === values.term)}
                width="110px"
                onChange={e => {
                  if (values.term === e?.value) return
                  setFieldValue('term', e?.value)
                  handleOffSwitch()
                  handleResetDataChart()
                }}
              />
            </>
          )}
        </Flex>
      </Flex>
    </Flex>
  )
}

export default FieldWordMapWrapper
