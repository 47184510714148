import { Box, Flex, Text, Button } from '@chakra-ui/react'
import { SelectField } from 'components/atoms'
import React, { Dispatch, FC, SetStateAction } from 'react'
import { Modal, Button as CusBtn } from 'components/fromAdvisor/elements'
import { ContentAskAI } from '../ContentAskAI'

type ModalAIAdviceProps = {
  value: string | number | null
  isOpen: boolean
  AIAnswer: { data: string; loading: boolean; done: boolean }
  isValues: boolean
  askAIOptions: { label: string; value: number | string }[]
  isDisableBtn?: boolean
  isToggleButton: boolean
  conversationId: number | null
  onClose: () => void
  onSubmit?: () => void
  setExpanded: React.Dispatch<React.SetStateAction<boolean>>
  setValueAskAI: Dispatch<SetStateAction<number | null>>
  onCancelChatAi: () => void
  setRenderPrompt?: React.Dispatch<React.SetStateAction<string>>
  setConversationId: React.Dispatch<React.SetStateAction<number | null>>
  setShowConfirmModal: React.Dispatch<React.SetStateAction<boolean>>
  setAIAnswer: React.Dispatch<
    React.SetStateAction<{ data: string; loading: boolean; done: boolean }>
  >
}

/**
 * ModalAIAdvice molecules
 * @constructor
 */
const ModalAIAdvice: FC<ModalAIAdviceProps> = ({
  value,
  isOpen,
  AIAnswer,
  isValues,
  askAIOptions,
  conversationId,
  isToggleButton,
  isDisableBtn = false,
  onClose,
  onSubmit,
  setExpanded,
  setAIAnswer,
  setValueAskAI,
  onCancelChatAi,
  setRenderPrompt,
  setConversationId,
  setShowConfirmModal,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      isShowIconClose={false}
      maxWidth="683px"
      onClose={onClose}
    >
      <Box
        bg="white.base"
        borderRadius="20px"
        boxSizing="border-box"
        padding="24px 32px"
        w="100%"
      >
        <Flex
          alignItems="center"
          justifyContent="space-between"
          mb="12px"
          w="100%"
        >
          <Flex alignItems="center" gap="22px" w="100%">
            <Text
              color="black.100"
              fontSize="lg"
              fontWeight="bold"
              height="100%"
              letterSpacing="-0.02em"
              lineHeight="tall"
              maxH="511px"
              minW="fit-content"
            >
              AIアドバイス
            </Text>
            <SelectField
              height="40px"
              isDisabled={!AIAnswer.done}
              maxWidth="full"
              name="askAI"
              options={askAIOptions}
              placeholder="当社の最新の経営・財務・営業戦略は？ "
              valueOption={askAIOptions.find(it => it.value === value)}
              onChange={e => {
                if (e?.value !== value) {
                  setAIAnswer(prev => ({
                    ...prev,
                    data: '',
                    loading: false,
                    done: true,
                  }))
                  setConversationId(null)
                  setRenderPrompt && setRenderPrompt('')
                  setValueAskAI(Number(e?.value))
                }
              }}
            />
            {isToggleButton ? (
              <CusBtn
                bgColor={'white'}
                border={'1px solid #0084ff'}
                color="blue.900"
                fontSize={'16px'}
                fontWeight={'bold'}
                h={'40px'}
                letterSpacing={'-0.02px'}
                lineHeight={'shorter'}
                minW={'122px'}
                px={5}
                text="キャンセル"
                onClick={onCancelChatAi}
              />
            ) : (
              <CusBtn
                bgColor={'blue.900'}
                border={'none'}
                color="white.50"
                fontSize={'16px'}
                fontWeight={'bold'}
                h={'40px'}
                isDisabled={isDisableBtn}
                letterSpacing={'-0.02px'}
                lineHeight={'shorter'}
                minW={'122px'}
                px={5}
                text="実行"
                onClick={() => {
                  if (onSubmit) {
                    onSubmit()
                  }
                }}
              />
            )}
          </Flex>
        </Flex>
        <ContentAskAI
          content={AIAnswer}
          heightContent="425px"
          isCopyBtn={true}
          isDone={AIAnswer.done}
          isHidden={false}
          isOpenModal={isOpen}
          isValues={isValues}
        />
        <Flex gap="12px" justifyContent="center" mt="24px">
          <Button
            _focusVisible={{}}
            _hover={{
              bgColor: 'blue.900',
            }}
            bgColor="blue.900"
            border="none"
            borderRadius="12px"
            gap="8px"
            height="48px"
            isDisabled={!conversationId}
            maxW="280px"
            padding="12px"
            width="100%"
            onClick={() => {
              setExpanded(false)
              setShowConfirmModal(true)
            }}
          >
            <Text
              color="white.50"
              fontSize="md"
              fontWeight="bold"
              letterSpacing="0.2px"
              lineHeight="shorter"
            >
              続けて質問
            </Text>
            <Text
              color="white.50"
              fontSize="xs"
              fontWeight="normal"
              letterSpacing="0.2px"
              lineHeight="shorter"
            >
              (AIチャットへ切替)
            </Text>
          </Button>
          <Button
            _focusVisible={{}}
            _hover={{
              bgColor: 'white.base',
            }}
            bgColor="white.base"
            border="1px solid"
            borderColor="blue.900"
            borderRadius="12px"
            color="blue.900"
            height="48px"
            maxW="280px"
            padding="12px"
            width="100%"
            onClick={onClose}
          >
            とじる
          </Button>
        </Flex>
      </Box>
    </Modal>
  )
}

export default ModalAIAdvice
