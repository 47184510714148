import React, { FC, useState } from 'react'
import { Box, Flex, Image, SimpleGrid, Text, useToast } from '@chakra-ui/react'
import styled from '@emotion/styled'
import { Button, Checkbox, Modal } from 'components/fromAdvisor/elements'
import { icons } from 'constant'
import { useDispatch, useSelector } from 'react-redux'
import { auth } from 'context/Auth/auth.selector'
import { chatAIServices } from 'services'
import { userUpdateAgentGuide } from 'context/Auth/auth.slice'

type ModalMilizeAgentProps = {
  isOpen: boolean
  onClose: () => void
}

const data = [
  {
    icon: icons.GLOBE_EARTH,
    description: [
      {
        label: 'できること',
        value: '最新の金融ニュースや市場動向を迅速に調査。',
      },
      {
        label: '活用例',
        value: '新興市場のトレンドや規制変更の情報を検索して投資戦略に活用。',
      },
    ],
    label: 'Google',
  },
  {
    icon: icons.SPIDER,
    description: [
      {
        label: 'できること',
        value: '特定のウェブページからデータを抽出して整理。',
      },
      {
        label: '活用例',
        value: '中央銀行の政策発表ページから最新の金利情報を自動取得。',
      },
    ],
    label: 'Spider',
  },
  {
    icon: icons.WRITING,
    description: [
      {
        label: 'できること',
        value: 'PDF形式のレポートやプレゼン資料を効率的に作成・編集。',
      },
      {
        label: '活用例',
        value: '投資家向けに資産運用報告書を作成し、配布用に整える。',
      },
    ],
    label: 'Writing',
  },
  {
    icon: icons.ACADEMIC,
    description: [
      {
        label: 'できること',
        value: '学術論文や専門資料の検索と分析。',
      },
      {
        label: '活用例',
        value:
          '金融リスク管理やポートフォリオ理論に関する研究論文を調査して社内研修に活用。',
      },
    ],
    label: 'Academic',
  },
]

const ModalMilizeAgent: FC<ModalMilizeAgentProps> = ({ isOpen, onClose }) => {
  const toast = useToast()
  const dispatch = useDispatch()
  const userLogin = useSelector(auth)

  const [loading, setLoading] = useState(false)
  const [isChecked, setIsChecked] = useState(
    Boolean(userLogin?.disable_show_tutorial),
  )

  const handleClickCheckbox = () => {
    setIsChecked(prev => !prev)
  }

  const handleCloseModal = async () => {
    if (userLogin === null || isChecked === userLogin?.disable_show_tutorial) {
      onClose()
      return
    }
    try {
      setLoading(true)
      let notification = ''
      if (isChecked) {
        const { message } = await chatAIServices.putDisableSearchAgentGuide()
        notification = message
      } else {
        const { message } = await chatAIServices.putEnableSearchAgentGuide()
        notification = message
      }
      dispatch(
        userUpdateAgentGuide({
          user: {
            ...userLogin,
            disable_show_tutorial: isChecked,
          },
        }),
      )
      toast({ status: 'success', description: notification })
      setLoading(false)
    } catch (error) {
      setLoading(false)
      toast({
        status: 'error',
        description: 'Update search agent guide error',
      })
    }
    onClose()
  }

  return (
    <Modal
      closeOnOverlayClick={true}
      isOpen={isOpen}
      isShowIconClose={true}
      maxHeight="fit-content"
      maxWidth={'960px'}
      onClose={handleCloseModal}
    >
      <Box display="flex" flexDirection="column" padding="40px">
        <Text
          color={'black.100'}
          fontSize={'xl'}
          fontWeight={'bold'}
          lineHeight={'32px'}
          mb={'24px'}
        >
          AIチャット専用ツールについて
        </Text>
        <SimpleGrid columns={2} gap={'20px'}>
          {data.map((item, index) => (
            <Flex
              key={index}
              alignItems={'flex-start'}
              backgroundColor={'white'}
              borderRadius={'12px'}
              gap={'12px'}
              padding="12px"
            >
              <Image alt={item.label} src={item.icon} />
              <Flex direction={'column'} gap={'8px'}>
                <Text
                  color={'black.100'}
                  fontSize={'md'}
                  fontWeight={'bold'}
                  lineHeight={'28px'}
                >
                  {item.label}
                </Text>
                <Flex direction={'column'}>
                  {item.description.map((it, idx) => (
                    <Text
                      key={idx}
                      color={'black.100'}
                      display={'inline-block'}
                      fontSize={'14px'}
                      lineHeight={'20px'}
                    >
                      <b>{it.label}: </b>
                      {it.value}
                    </Text>
                  ))}
                </Flex>
              </Flex>
            </Flex>
          ))}
        </SimpleGrid>
        <Flex
          alignItems="center"
          flexDirection="column"
          gap="24px"
          justify={'center'}
          mt="24px"
        >
          <Flex alignItems={'center'} gap={2}>
            <Checkbox
              defaultChecked={isChecked}
              isChecked={isChecked}
              name={'agentGuide'}
              onChange={handleClickCheckbox}
            />
            <LabelText>次回から表示しない</LabelText>
          </Flex>
          <Button
            backgroundColor="white.50"
            border="1px solid #0084FF"
            borderRadius="12px"
            color="blue.900"
            fontSize="14px"
            fontWeight="bold"
            height="40px"
            isLoading={loading}
            text="とじる"
            width="225px"
            onClick={handleCloseModal}
          />
        </Flex>
      </Box>
    </Modal>
  )
}

export default ModalMilizeAgent

const LabelText = styled(Text)`
  color: ${props => props.theme.colors.black[100]};
  font-size: ${props => props.theme.fontSizes.sm};
  line-height: ${props => props.theme.lineHeights.shorter};
`
