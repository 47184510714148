import { Box, CircularProgress, Flex, SimpleGrid, Text } from '@chakra-ui/react'
import ChartWrapper from 'components/molecules/ChartWrapper'
import WordCloud from 'components/molecules/WordCloud'
import React, { FC } from 'react'
import { DataChartMoreType, DataChartType } from 'types/chart'
import { FormValues } from '../hook'

type RenderWordMapProps = {
  values: FormValues
  isNotWordmap: boolean
  dataChart: DataChartType
  displaySizeChart: number
  containerChartWidth: number
  containerChartHeight: number
  dataChartMore: DataChartMoreType
  handleOpenZoom: (info: {
    isShowAll: boolean
    typeChart: string
    index: number | null
  }) => void
}

const RenderWordMap: FC<RenderWordMapProps> = ({
  values,
  dataChart,
  isNotWordmap,
  dataChartMore,
  displaySizeChart,
  containerChartWidth,
  containerChartHeight,
  handleOpenZoom,
}) => {
  return (
    <>
      {isNotWordmap && !dataChart.loading && (
        <Text
          color={'black.100'}
          fontSize={'16px'}
          textAlign={'center'}
          w="full"
        >
          データがありません。
        </Text>
      )}
      {dataChart.loading && (
        <Flex alignItems={'center'} h={'150px'} justify={'center'} w={'full'}>
          <CircularProgress isIndeterminate color="blue.900" size={10} />
        </Flex>
      )}
      {values.analysis_menu === '0' && !isNotWordmap && (
        <SimpleGrid columns={1} spacingX="16px" spacingY="4px">
          <Box w={`${containerChartWidth}px`}>
            <Flex align={'center'} justify={'space-between'}>
              <ChartWrapper
                handleOpenZoom={() => {
                  handleOpenZoom({
                    isShowAll: false,
                    typeChart: 'wordcloud',
                    index: 0,
                  })
                }}
                isBorderLeft={true}
                isShowButtonZoom={false}
                label="ワードマップ"
              />
            </Flex>
            <Box
              height={`${containerChartHeight}px`}
              width={`${containerChartWidth}px`}
            >
              <WordCloud
                height={containerChartHeight}
                width={containerChartWidth}
                words={dataChart.wordLesChart.dataWordCloud}
              />
            </Box>
          </Box>
          {/* {Boolean(!dataChart.wordLesChart.dataEchart.length) ? (
              <></>
            ) : dataChart.loading ? (
              <Box
                bgColor={'#FEFEFE'}
                borderRadius={'16px'}
                h={'396px'}
                mb="8px"
                w={'full'}
              ></Box>
            ) : (
              <Box
                w={
                  boxRef.current
                    ? isOpenDrawer
                      ? `calc(${widthChart - 32})`
                      : `calc(${widthChart - 40} / 2)`
                    : '100%'
                }
              >
                <Flex align={'center'} justify={'space-between'}>
                  <ChartWrapper
                    handleOpenZoom={() => {
                      handleOpenZoom({
                        isShowAll: false,
                        typeChart: 'echart',
                        index: null,
                      })
                    }}
                    isBorderLeft={true}
                    label="共起ネットワーク"
                  />
                </Flex>
                <Box h={550}>
                  <Echarts
                    ref={el => (ref.current[1] = el)}
                    data={dataChart.wordLesChart.dataEchart}
                    reloadChart={reloadChart}
                  />
                </Box>
              </Box>
            )} */}
        </SimpleGrid>
      )}
      {values.analysis_menu !== '0' && !isNotWordmap && (
        <SimpleGrid columns={displaySizeChart} spacingX="16px" spacingY="4px">
          {(values.analysis_menu === '1'
            ? dataChart.dataListWordCloud.data
            : dataChart.dataTimeLine.data
          ).map((item, index) => (
            <Box key={index} w={`${containerChartWidth}px`}>
              <Flex align={'center'} justify={'space-between'}>
                <ChartWrapper
                  handleOpenZoom={() => {
                    handleOpenZoom({
                      isShowAll: false,
                      typeChart: 'wordcloud',
                      index: index,
                    })
                  }}
                  isBorderLeft={true}
                  label={item.title}
                />
              </Flex>
              <Box
                height={`${containerChartHeight}px`}
                width={`${containerChartWidth}px`}
              >
                <WordCloud
                  height={containerChartHeight}
                  width={containerChartWidth}
                  words={item.words_cloud}
                />
              </Box>
            </Box>
          ))}
          {dataChartMore.data.map((item, index) => (
            <Box key={index}>
              <Flex align={'center'} justify={'space-between'}>
                <ChartWrapper
                  handleOpenZoom={() => {
                    handleOpenZoom({
                      isShowAll: false,
                      typeChart: 'wordcloud',
                      index: dataChart.dataListWordCloud.data.length + index,
                    })
                  }}
                  isBorderLeft={true}
                  label={item.title}
                />
              </Flex>
              <Box
                height={`${containerChartHeight}px`}
                width={`${containerChartWidth}px`}
              >
                <WordCloud
                  height={containerChartHeight}
                  width={containerChartWidth}
                  words={item.words_cloud}
                />
              </Box>
            </Box>
          ))}
        </SimpleGrid>
      )}
    </>
  )
}

export default RenderWordMap
