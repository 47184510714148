export const newsDate = {
  // type: 'range-date',
  // is_show_popover: true,
  // field_name: 'start_date',
  // field_name_2: 'end_date',
  isSort: true,
}

export const subjectCompany = {
  type: 'text',
  is_show_popover: true,
  field_name: 'subject_company',
  is_show_filter: true,
}

export const categoryMeta = {
  type: 'select',
  is_show_popover: true,
  field_name: 'category',
  is_show_filter: true,
}

export const contactPerson = {
  type: 'text',
  is_show_popover: true,
  field_name: 'contact_person_name',
  is_show_filter: true,
}

export const newsTitle = {
  type: 'title',
  is_show_popover: true,
  field_name: 'news_title',
  field_name_2: 'favorite',
  text: 'お気に入り登録',
  is_show_filter: true,
  separator: '・',
  text_change_boolean: 'お気に入り登録',
}

export const score = {
  type: 'range-number',
  is_show_popover: true,
  field_name: 'score_article_gte',
  field_name_2: 'score_article_lte',
  is_show_filter: true,
  separator: '~',
  textTooltip: '各記事を踏まえ取引可能性の高さをスコアで表示したものです',
  isSort: true,
}

export const sentimentMeta = {
  type: 'checkbox-group',
  is_show_popover: true,
  field_name: 'sentiment',
  options: [
    { label: 'ポジティブ', value: 'positive' },
    { label: 'ニュートラル', value: 'neutral' },
    { label: 'ネガティブ', value: 'negative' },
    { label: '-', value: '-' },
  ],
  columns: 1,
  mode: 'list-icon',
  is_show_filter: true,
  textTooltip: 'ニュースの内容がネガティブかポジティブかの判定です',
}

export const sauceMeta = {
  type: 'select',
  is_show_popover: true,
  field_name: 'sauce',
  is_show_filter: true,
}

export const productsMeta = {
  type: 'checkbox-group',
  is_show_popover: true,
  field_name: 'products',
  columns: 2,
  mode: 'list',
  widthPopover: 472,
  is_show_filter: true,
}
