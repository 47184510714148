/* eslint-disable @typescript-eslint/no-unused-vars */
import { useToast } from '@chakra-ui/react'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useFormik } from 'formik'
import { AxiosError } from 'axios'
import {
  askAIServices,
  chatAIServices,
  newServices,
  storageServices,
} from 'services'
import {
  convertString,
  convertTargetArticle,
} from 'components/fromAdvisor/templates/chatAI/tabs/ChatWithAI/components/MainChat/MainChat.utils'
import { NewsType } from 'services/newServices'
import { Option } from 'types/conmon'
import Handlebars from 'handlebars'
import { useLocation } from 'react-router-dom'
import { VariableNewsPromptType } from 'services/chatAIServices'

export type AIAdviceProps = {
  newsId?: string
}

export const useAIAdvice = (props: AIAdviceProps) => {
  const { newsId } = props
  const toast = useToast()
  const location = useLocation()
  const socketRef = useRef<WebSocket | null>(null)
  const accessToken = storageServices.getAccessToken()
  const abortControllerRef = useRef<AbortController | null>(null)

  const [expanded, setExpanded] = useState(false)
  const [showConfirmModal, setShowConfirmModal] = useState(false)
  const [newsDetail, setNewsDetail] = useState<NewsType>()
  const [askAIOptions, setAskAIOptions] = useState<Option[]>([])
  const [valueSelected, setValueSelected] = useState<number | null>(null)
  const [conversationId, setConversationId] = useState<number | null>(null)

  const [AIAnswer, setAIAnswer] = useState<{
    data: string
    loading: boolean
    done: boolean
  }>({
    data: '',
    loading: false,
    done: true,
  })

  const fetchDataNewDetail = useCallback(async () => {
    try {
      if (newsId) {
        const { data } = await newServices.getNewDetail({
          id: newsId,
          list_news_ai_id: location?.state?.newsAiId,
        })
        setNewsDetail(data)
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        console.log('error', error)
      }
    }
  }, [location?.state?.newsAiId, newsId])

  /**
   * Show all text
   */
  const toggleExpanded = () => {
    setExpanded(!expanded)
  }

  const formik = useFormik<{ askAI: number | null }>({
    initialValues: {
      askAI: null,
    },
    enableReinitialize: true,
    onSubmit() {},
  })
  const { values, setFieldValue } = formik

  const isToggleButton =
    AIAnswer.loading || (!AIAnswer.loading && !AIAnswer.done)

  const isValues = Boolean(
    values.askAI === valueSelected && valueSelected !== null,
  )
  const isDisableBtn =
    !valueSelected ||
    AIAnswer.loading ||
    (valueSelected === values.askAI && Boolean(AIAnswer.data))

  /**
   * handleCloseModal
   */
  const handleCloseModal = () => {
    setExpanded(false)
  }

  /**
   * get data option
   */
  const fetchAskOptionData = useCallback(async () => {
    if (!newsDetail) return
    try {
      const { data } = await askAIServices.getOptionPromptNews({
        is_news_reports: Boolean(newsDetail?.company_report?.file_path),
      })
      const convertOption = data.map(prompt => ({
        label: prompt.title,
        value: prompt.id,
      }))
      setAskAIOptions(convertOption)
    } catch (error) {
      if (error instanceof AxiosError) {
        console.log('error', error)
      }
    }
  }, [newsDetail])

  const handleSendChatAi = async () => {
    if (!valueSelected || !newsId || !accessToken) return
    try {
      const controller = new AbortController()
      abortControllerRef.current = controller
      setConversationId(null)
      setFieldValue('askAI', valueSelected)
      setAIAnswer(prev => ({ ...prev, data: '', loading: true, done: false }))

      const { data: dataPrompt } = await chatAIServices.getPromptNewsDetail(
        {
          prompt_id: +valueSelected,
          news_id: +newsId,
        },
        controller,
      )

      let renderedPrompt = dataPrompt.prompt_content.trim()

      try {
        const template = Handlebars.compile(dataPrompt.prompt_content.trim())
        renderedPrompt = template(dataPrompt.variables)
      } catch (error) {
        console.log('error', error)
      }

      if (!renderedPrompt) return

      connectWebsocket(renderedPrompt, dataPrompt.variables)
    } catch (error) {
      setAIAnswer(prev => ({
        ...prev,
        data: '',
        loading: false,
        done: true,
      }))
      setConversationId(null)
      setFieldValue('askAI', null)
      if (socketRef.current) {
        socketRef.current.close()
        socketRef.current = null
        console.log('WebSocket connection closed')
      }
    }
  }

  const connectWebsocket = (
    renderPrompt: string,
    variablePrompt: VariableNewsPromptType,
  ) => {
    try {
      socketRef.current = new WebSocket(
        `${process.env.REACT_APP_WEBSOCKET_URL}/api/v1/advisor/chat_with_ai/ws_stream`,
      )

      const handleSocketOpen = () => {
        if (
          socketRef.current &&
          socketRef.current.readyState === WebSocket.OPEN
        ) {
          console.log('WS connected')

          const messAuthen = {
            type: 'auth_req',
            data: { Authorization: `Bearer ${accessToken}` },
          }

          socketRef.current?.send(JSON.stringify(messAuthen))

          const convertedString = convertString(renderPrompt || '')
          const convertArticle = convertTargetArticle(
            renderPrompt,
            newsDetail?.news_body,
          )
          const renderQuestion = convertArticle ? convertArticle : renderPrompt
          const currentPrompt = askAIOptions.find(
            item => item.value === valueSelected,
          )
          let message = {
            type: 'stream_req',
            data: {
              prompt_html: `<p>${renderQuestion}</p>`,
              prompt_text: renderQuestion,
              prompt_question: convertedString
                ? convertedString
                : renderQuestion,
              prompt_type: '',
              current_prompt_id: null,
              news_id: newsId,
              prompt_title: currentPrompt?.label ?? '',
              prompt_id: currentPrompt?.value ?? null,
              variables: variablePrompt,
            },
          }

          socketRef.current?.send(JSON.stringify(message))
        } else {
          console.error('WebSocket is not open')
        }
      }

      const handleSocketMessage = (event: { data: string }) => {
        const objMess = JSON.parse(event.data)

        switch (objMess.type) {
          case 'stream_res':
            setAIAnswer(prev => ({
              ...prev,
              data: prev.data + objMess?.data,
              loading: false,
            }))
            break

          case 'ai_conversation':
            if (!objMess?.data)
              return toast({
                status: 'error',
                description:
                  '何らかのエラーが発生しました。もう一度お試しください。',
              })
            setConversationId(objMess?.data.id)
            setAIAnswer(prev => ({
              ...prev,
              data: prev.data,
              done: true,
            }))
            break

          default:
            break
        }
      }

      const handleSocketClose = () => {
        console.log('WebSocket is disconnected')
      }

      socketRef.current?.addEventListener('open', handleSocketOpen)
      socketRef.current?.addEventListener('message', handleSocketMessage)
      socketRef.current?.addEventListener('close', handleSocketClose)
    } catch (error) {
      console.error('WebSocket connection failed:', error)
      setConversationId(null)
      setFieldValue('askAI', null)
      setAIAnswer(prev => ({
        ...prev,
        done: true,
        loading: false,
      }))
    }
  }

  const disconnectWebSocket = () => {
    try {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort()
        abortControllerRef.current = null
      }

      setAIAnswer(prev => ({
        ...prev,
        data: '',
        loading: false,
        done: true,
      }))
      setConversationId(null)
      setFieldValue('askAI', null)

      if (socketRef.current) {
        socketRef.current.close()
        socketRef.current = null
        console.log('WebSocket connection closed')
      }
    } catch (error) {
      console.error('Error disconnecting WebSocket:', error)
      setConversationId(null)
      setAIAnswer(prev => ({
        ...prev,
        done: true,
        loading: false,
      }))
      setFieldValue('askAI', null)
    }
  }

  useEffect(() => {
    fetchDataNewDetail()
  }, [fetchDataNewDetail])

  useEffect(() => {
    fetchAskOptionData()
  }, [fetchAskOptionData])

  useEffect(() => {
    return () => {
      if (socketRef.current) {
        socketRef.current.close()
        socketRef.current = null
      }
    }
  }, [])

  return {
    ...props,
    formik,
    values,
    isValues,
    AIAnswer,
    expanded,
    isDisableBtn,
    askAIOptions,
    valueSelected,
    isToggleButton,
    conversationId,
    showConfirmModal,
    setAIAnswer,
    setExpanded,
    setFieldValue,
    toggleExpanded,
    handleCloseModal,
    setValueSelected,
    handleSendChatAi,
    setConversationId,
    setShowConfirmModal,
    disconnectWebSocket,
  }
}
