import { Text } from '@chakra-ui/react'
import React, { FC } from 'react'
import { Button } from 'components/fromAdvisor/elements'
import { ChartWrapperProps, useChartWrapper } from './hook'

const ChartWrapper: FC<ChartWrapperProps> = props => {
  const {
    label,
    handleOpenZoom,
    isBorderLeft = false,
    isShowButtonZoom = true,
  } = useChartWrapper(props)
  return (
    <>
      <Text
        borderLeft={isBorderLeft ? '2px solid #0084FF' : 'none'}
        fontSize="18px"
        fontWeight="bold"
        lineHeight="shorter"
        mb={'8px'}
        minW="fit-content"
        px="8px"
      >
        {label}
      </Text>
      {isShowButtonZoom && (
        <Button
          _hover={{
            bgColor: 'white.50',
          }}
          bgColor="white.50"
          border="solid 1px"
          borderColor="blue.900"
          borderRadius="12px"
          color="blue.900"
          fontSize="sm"
          fontWeight="bold"
          h="32px"
          lineHeight="shorter"
          mb={'8px'}
          minW={'103px'}
          padding="8px 16px"
          text="全画面表示"
          onClick={handleOpenZoom}
        />
      )}
    </>
  )
}

export default ChartWrapper
