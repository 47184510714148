import { useToast } from '@chakra-ui/react'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useFormik } from 'formik'
import { convertString } from 'components/fromAdvisor/templates/chatAI/tabs/ChatWithAI/components/MainChat/MainChat.utils'
import { askAIServices, chatAIServices, storageServices } from 'services'
import { Option } from 'types/conmon'
import { useParams } from 'react-router-dom'
import Handlebars from 'handlebars'
import { VariablesCompanyPromptType } from 'services/chatAIServices'

export type AIAdviceCompanyProps = {}
export const useAIAdviceCompany = (props: AIAdviceCompanyProps) => {
  const toast = useToast()
  const { id: companyId } = useParams()
  const socketRef = useRef<WebSocket | null>(null)
  const accessToken = storageServices.getAccessToken()
  const abortControllerRef = useRef<AbortController | null>(null)

  const [expanded, setExpanded] = useState(false)
  const [showConfirmModal, setShowConfirmModal] = useState(false)
  const [valueSelected, setValueSelected] = useState<number | null>(null)
  const [conversationId, setConversationId] = useState<number | null>(null)
  const [optionPromptCompany, setOptionPromptCompany] = useState<Option[]>([])

  const [AIAnswer, setAIAnswer] = useState<{
    data: string
    loading: boolean
    done: boolean
  }>({
    data: '',
    loading: false,
    done: true,
  })

  /**
   * get data option
   */
  const fetchOptionPropmtCompany = useCallback(async () => {
    try {
      const { data } = await askAIServices.getOptionPromptCompany()
      const convertOption = data.map(prompt => ({
        label: prompt.title,
        value: prompt.id,
      }))

      setOptionPromptCompany(convertOption)
    } catch (error: any) {
      toast({
        status: 'error',
        description: error?.response?.data?.message,
      })
    }
  }, [toast])

  const formik = useFormik<{ askAI: number | null }>({
    initialValues: {
      askAI: null,
    },
    /**
     *
     */
    onSubmit() {},
  })
  const { values, setFieldValue } = formik

  const isToggleButton =
    AIAnswer.loading || (!AIAnswer.loading && !AIAnswer.done)

  const isValues = Boolean(
    values.askAI === valueSelected && valueSelected !== null,
  )

  const isDisableBtn =
    !valueSelected ||
    AIAnswer.loading ||
    (valueSelected === values.askAI && Boolean(AIAnswer.data))

  /**
   * Show all text
   */
  const toggleExpanded = () => {
    setExpanded(!expanded)
  }

  /**
   * handleCloseModal
   */
  const handleCloseModal = () => {
    setExpanded(false)
  }

  /**
   * handle convert prompt from option
   */
  const handleSendChatAi = async () => {
    if (!valueSelected || !accessToken || !companyId) return
    try {
      const controller = new AbortController()
      abortControllerRef.current = controller
      setConversationId(null)
      setFieldValue('askAI', valueSelected)
      setAIAnswer(prev => ({ ...prev, data: '', loading: true, done: false }))

      const { data: dataPrompt } = await chatAIServices.getPromptCompanyDetail(
        {
          prompt_id: +valueSelected,
          company_id: +companyId,
        },
        controller,
      )

      let renderedPrompt = dataPrompt.prompt_content.trim()

      try {
        const template = Handlebars.compile(dataPrompt.prompt_content.trim())
        renderedPrompt = template(dataPrompt.variables)
      } catch (error) {
        console.log('error', error)
      }

      if (!renderedPrompt) return

      connectWebsocket(renderedPrompt, dataPrompt.variables)
    } catch (error) {
      setAIAnswer(prev => ({
        ...prev,
        data: '',
        loading: false,
        done: true,
      }))
      setConversationId(null)
      setFieldValue('askAI', null)

      if (socketRef.current) {
        socketRef.current.close()
        socketRef.current = null
        console.log('WebSocket connection closed')
      }
    }
  }

  /**
   * Connect websocket
   */
  const connectWebsocket = (
    renderPrompt: string,
    variablePrompt: VariablesCompanyPromptType,
  ) => {
    try {
      socketRef.current = new WebSocket(
        `${process.env.REACT_APP_WEBSOCKET_URL}/api/v1/advisor/company/ai_advise/ws_stream`,
      )

      const handleSocketOpen = () => {
        if (
          socketRef.current &&
          socketRef.current.readyState === WebSocket.OPEN
        ) {
          const messAuthen = {
            type: 'auth_req',
            data: { Authorization: `Bearer ${accessToken}` },
          }

          socketRef.current?.send(JSON.stringify(messAuthen))

          const convertedString = convertString(renderPrompt || '')
          let message = {
            type: 'stream_req',
            data: {
              prompt_id: valueSelected,
              company_id: Number(companyId),
              prompt_html: `<p>${renderPrompt}</p>`,
              prompt_text: renderPrompt,
              prompt_question: convertedString ? convertedString : renderPrompt,
              current_prompt_id: null,
              ai_conversation_id: null,
              variables: variablePrompt,
            },
          }

          socketRef.current?.send(JSON.stringify(message))
        } else {
          console.error('WebSocket is not open')
        }
      }

      const handleSocketMessage = (event: { data: string }) => {
        const objMess = JSON.parse(event.data)

        switch (objMess.type) {
          case 'additional_res':
            setConversationId(objMess?.data?.conversation_id)
            setAIAnswer(prev => ({
              ...prev,
              data: prev.data,
              done: true,
            }))
            break

          case 'stream_res':
            setAIAnswer(prev => ({
              ...prev,
              data: prev.data + objMess?.data,
              loading: false,
            }))
            break

          default:
            break
        }
      }

      const handleSocketClose = () => {
        console.log('WebSocket is disconnected')
      }

      socketRef.current?.addEventListener('open', handleSocketOpen)
      socketRef.current?.addEventListener('message', handleSocketMessage)
      socketRef.current?.addEventListener('close', handleSocketClose)
    } catch (error) {
      console.error('WebSocket connection failed:', error)
      setConversationId(null)
      setAIAnswer(prev => ({
        ...prev,
        done: true,
        loading: false,
      }))
      setFieldValue('askAI', null)
    }
  }

  const disconnectWebSocket = () => {
    try {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort()
        abortControllerRef.current = null
      }

      setAIAnswer(prev => ({
        ...prev,
        data: '',
        loading: false,
        done: true,
      }))
      setConversationId(null)
      setFieldValue('askAI', null)

      if (socketRef.current) {
        socketRef.current.close()
        socketRef.current = null
        console.log('WebSocket connection closed')
      }
    } catch (error) {
      console.error('Error disconnecting WebSocket:', error)
      setConversationId(null)
      setAIAnswer(prev => ({
        ...prev,
        done: true,
        loading: false,
      }))
      setFieldValue('askAI', null)
    }
  }

  useEffect(() => {
    fetchOptionPropmtCompany()
  }, [fetchOptionPropmtCompany])

  useEffect(() => {
    return () => {
      if (socketRef.current) {
        socketRef.current.close()
        socketRef.current = null
      }
    }
  }, [])

  return {
    ...props,
    formik,
    values,
    AIAnswer,
    isValues,
    expanded,
    isDisableBtn,
    valueSelected,
    isToggleButton,
    conversationId,
    showConfirmModal,
    optionPromptCompany,
    setAIAnswer,
    setExpanded,
    toggleExpanded,
    setValueSelected,
    handleCloseModal,
    handleSendChatAi,
    setConversationId,
    setShowConfirmModal,
    disconnectWebSocket,
  }
}
