import { Flex, Text } from '@chakra-ui/react'
import { SelectField } from 'components/atoms'
import React, { FC } from 'react'
import { RenderActionOpenDrawerProps, useRenderActionOpenDrawer } from './hook'

const RenderActionOpenDrawer: FC<RenderActionOpenDrawerProps> = props => {
  const { values, setFieldValue, termOptions, year, quarter, handleOffSwitch } =
    useRenderActionOpenDrawer(props)
  return (
    <Flex align="center" gap={'4px'}>
      <SelectField
        height="40px"
        isDisabled={true}
        isInForm={false}
        name="targetYear"
        options={[
          {
            label: `${year}年度`,
            value: `${year}`,
          },
        ]}
        placeholder="年度"
        valueOption={{
          label: `${year}年度`,
          value: `${year}`,
        }}
        width="115px"
        onChange={e => {
          setFieldValue('targetYear', e?.value)
          setFieldValue('quarters', '')
        }}
      />
      {quarter !== 0 && (
        <SelectField
          height="40px"
          isDisabled={true}
          name="quarters"
          options={[
            {
              label: `第${quarter}四半期`,
              value: `${quarter}`,
            },
          ]}
          placeholder="四半期"
          valueOption={{
            label: `第${quarter}四半期`,
            value: `${quarter}`,
          }}
          width="93px"
          onChange={e => {
            setFieldValue('quarters', e?.value)
          }}
        />
      )}
      <Text
        color="black.100"
        fontSize="14px"
        fontWeight="normal"
        lineHeight="shorter"
        minW="fit-content"
      >
        から過去
      </Text>
      <SelectField
        height="40px"
        name="term"
        options={termOptions}
        placeholder="期分"
        valueOption={termOptions.find(it => it.value === values.term)}
        width="90px"
        onChange={e => {
          setFieldValue('term', e?.value)
          handleOffSwitch()
        }}
      />
    </Flex>
  )
}

export default RenderActionOpenDrawer
