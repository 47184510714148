import { useCallback, useEffect, useRef, useState } from 'react'
import { useFormik } from 'formik'
import { Option } from 'types/conmon'
import { chatAIServices, storageServices } from 'services'

export type TalkStoryProps = {}

export const useTalkStory = (props: TalkStoryProps) => {
  const ref = useRef<any>()
  const socketRef = useRef<WebSocket | null>(null)

  const accessToken = storageServices.getAccessToken()

  const [isShow, setIsShow] = useState(false)
  const [expanded, setExpanded] = useState(false)
  const [isDisableSelect, setIsDisableSelect] = useState(false)
  const [showConfirmModal, setShowConfirmModal] = useState(false)
  const [AIAnswer, setAIAnswer] = useState('')
  const [conversationId, setConversationId] = useState<null | number>(null)

  const [options, setOptions] = useState<{
    industry: Option[]
    story: Option[]
  }>({ industry: [], story: [] })

  const [stateAIAnswer, setStateAIAnswer] = useState<{
    loading: boolean
    done: boolean
  }>({ loading: false, done: true })

  const [valueSelected, setValueSelected] = useState<{
    industry: number | null
    story: number | null
  }>({ industry: null, story: null })

  const formik = useFormik<{
    selectIndustry: number | null
    selectTalkStory: number | null
  }>({
    initialValues: {
      selectIndustry: null,
      selectTalkStory: null,
    },
    onSubmit() {},
  })

  const { values, setValues } = formik

  const isToggleButton =
    stateAIAnswer.loading || (!stateAIAnswer.loading && !stateAIAnswer.done)

  const isDisableButton =
    !valueSelected.industry ||
    !valueSelected.story ||
    (values.selectIndustry === valueSelected.industry &&
      values.selectTalkStory === valueSelected.story &&
      Boolean(AIAnswer))

  /**
   * Show all text
   */
  const toggleExpanded = () => {
    setExpanded(!expanded)
  }

  /**
   * Get data options
   */
  const fetchDataOptions = useCallback(async () => {
    try {
      const [industryOptions, storyOptions] = await Promise.all([
        chatAIServices.getOptionsIndustry(),
        chatAIServices.getOptionsStory(),
      ])

      const convertIndustryOptions = industryOptions.data.map(option => ({
        label: option.name,
        value: option.id,
      }))

      const convertStoryOptions = storyOptions.data.map(option => ({
        label: option.title,
        value: option.id,
      }))

      setOptions(prev => ({
        ...prev,
        industry: convertIndustryOptions,
        story: convertStoryOptions,
      }))
    } catch (error) {
      console.log('error', error)
    }
  }, [])

  /**
   * Connect websocket
   */
  const handleSendChatAi = () => {
    const { industry, story } = valueSelected

    if (!accessToken || !industry || !story || stateAIAnswer.loading) return

    setConversationId(null)
    setAIAnswer('')
    setValues(prev => ({
      ...prev,
      selectIndustry: industry,
      selectTalkStory: story,
    }))

    try {
      socketRef.current = new WebSocket(
        `${process.env.REACT_APP_WEBSOCKET_URL}/api/v1/advisor/news/ai_recommend/ws_stream`,
      )

      const handleSocketOpen = () => {
        if (
          socketRef.current &&
          socketRef.current.readyState === WebSocket.OPEN
        ) {
          console.log('WebSocket connected')
          setStateAIAnswer({ loading: true, done: false })
          setIsDisableSelect(true)

          const authMessage = {
            type: 'auth_req',
            data: { Authorization: `Bearer ${accessToken}` },
          }
          socketRef.current?.send(JSON.stringify(authMessage))

          const streamMessage = {
            type: 'stream_req',
            data: {
              industry_id: industry,
              story_id: story,
            },
          }
          socketRef.current?.send(JSON.stringify(streamMessage))
        } else {
          console.error('WebSocket is not open')
        }
      }

      const handleSocketMessage = (event: MessageEvent) => {
        const objMessage = JSON.parse(event.data)

        if (objMessage?.type === 'additional_res') {
          setStateAIAnswer(prev => ({ ...prev, done: true }))
          setConversationId(objMessage?.data?.conversation_id)
          return
        }

        if (objMessage.type === 'stream_res') {
          setStateAIAnswer(prev => ({ ...prev, loading: false }))
          setAIAnswer(prev => prev + objMessage?.data)
        }
      }

      const handleSocketClose = () => {
        console.log('WebSocket disconnected')
        setIsDisableSelect(false)
      }

      const handleSocketError = (error: Event) => {
        console.error('WebSocket error:', error)
        setIsDisableSelect(false)
      }

      socketRef.current.addEventListener('open', handleSocketOpen)
      socketRef.current.addEventListener('message', handleSocketMessage)
      socketRef.current.addEventListener('close', handleSocketClose)
      socketRef.current.addEventListener('error', handleSocketError)
    } catch (error) {
      console.error('WebSocket connection failed:', error)
      setStateAIAnswer({ loading: false, done: true })
      setIsDisableSelect(false)
    }
  }

  const disconnectWebSocket = () => {
    try {
      if (socketRef.current) {
        socketRef.current.close()
        socketRef.current = null

        setConversationId(null)
        setAIAnswer('')
        setStateAIAnswer(prev => ({ ...prev, loading: false, done: true }))
        console.log('WebSocket connection closed')
      }
    } catch (error) {
      console.error('Error disconnecting WebSocket:', error)
      setConversationId(null)
      setAIAnswer('')
      setStateAIAnswer(prev => ({ ...prev, loading: false, done: true }))
    }
  }

  useEffect(() => {
    fetchDataOptions()
  }, [fetchDataOptions])

  useEffect(() => {
    if (ref.current?.clientHeight >= 168) {
      setIsShow(true)
    } else {
      setIsShow(false)
    }
  }, [AIAnswer])

  useEffect(() => {
    return () => {
      if (socketRef.current) {
        socketRef.current.close()
        socketRef.current = null
      }
    }
  }, [])

  return {
    ...props,
    ref,
    formik,
    isShow,
    options,
    AIAnswer,
    expanded,
    stateAIAnswer,
    valueSelected,
    isToggleButton,
    conversationId,
    isDisableSelect,
    isDisableButton,
    showConfirmModal,
    setExpanded,
    toggleExpanded,
    handleSendChatAi,
    setValueSelected,
    disconnectWebSocket,
    setShowConfirmModal,
  }
}
