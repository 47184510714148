/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box, Button, Flex, Text } from '@chakra-ui/react'
import { SelectField } from 'components/atoms'
import React, { FC } from 'react'
import { FormikProvider } from 'formik'

import { Button as CusBtn } from 'components/fromAdvisor/elements'
import ModalAIAdvice from '../ModalAIAdvice'
import { ContentAskAI } from '../ContentAskAI'
import ModalConfirmAIAdvice from '../ModalConfirmAIAdvice'
import { AIAdviceProps, useAIAdvice } from './hook'

/**
 * AIAdvice molecules
 * @constructor
 */
export const AIAdvice: FC<AIAdviceProps> = props => {
  const {
    formik,
    values,
    isValues,
    AIAnswer,
    expanded,
    isDisableBtn,
    askAIOptions,
    valueSelected,
    isToggleButton,
    conversationId,
    showConfirmModal,
    setAIAnswer,
    setExpanded,
    setFieldValue,
    toggleExpanded,
    handleCloseModal,
    setValueSelected,
    handleSendChatAi,
    setConversationId,
    setShowConfirmModal,
    disconnectWebSocket,
  } = useAIAdvice(props)
  return (
    <FormikProvider value={formik}>
      <Box boxSizing="border-box" padding="12px 16px 16px 16px" w="100%">
        <Flex
          alignItems="center"
          justifyContent="space-between"
          mb={'12px'}
          w="100%"
        >
          <Flex alignItems="center" gap="22px" w="100%">
            <SelectField
              heightListOptions="220px"
              isDisabled={AIAnswer.loading}
              maxWidth="full"
              minHeight="40px"
              name="askAI"
              options={askAIOptions}
              placeholder="連想されるビジネスは？ "
              valueOption={askAIOptions.find(it => it.value === valueSelected)}
              onChange={e => {
                if (values.askAI !== e?.value) {
                  setAIAnswer(prev => ({
                    ...prev,
                    data: '',
                    loading: false,
                    done: true,
                  }))
                  setConversationId(null)
                  setValueSelected(Number(e?.value))
                }
              }}
            />
            {isToggleButton ? (
              <CusBtn
                bgColor={'white'}
                border={'1px solid #0084ff'}
                color="blue.900"
                fontSize={'16px'}
                fontWeight={'bold'}
                h={'40px'}
                letterSpacing={'-0.02px'}
                lineHeight={'shorter'}
                minW={'122px'}
                px={5}
                text="キャンセル"
                onClick={disconnectWebSocket}
              />
            ) : (
              <CusBtn
                bgColor={'blue.900'}
                border={'none'}
                color="white.50"
                fontSize={'16px'}
                fontWeight={'bold'}
                h={'40px'}
                isDisabled={isDisableBtn}
                letterSpacing={'-0.02px'}
                lineHeight={'shorter'}
                minW={'122px'}
                px={5}
                text="実行"
                onClick={handleSendChatAi}
              />
            )}
            <Button
              _focusVisible={{}}
              _hover={{
                bgColor: 'blue.900',
              }}
              bgColor="blue.900"
              border="none"
              borderRadius="12px"
              gap="8px"
              height="40px"
              isDisabled={!conversationId}
              maxW="280px"
              padding="12px"
              width="100%"
              onClick={() => {
                // setExpanded(false)
                setShowConfirmModal(true)
              }}
            >
              <Text
                color="white.50"
                fontSize="md"
                fontWeight="bold"
                letterSpacing="0.2px"
                lineHeight="shorter"
              >
                続けて質問
              </Text>
              <Text
                color="white.50"
                fontSize="xs"
                fontWeight="normal"
                letterSpacing="0.2px"
                lineHeight="shorter"
              >
                (AIチャットへ切替)
              </Text>
            </Button>
          </Flex>
        </Flex>
        <ContentAskAI
          content={AIAnswer}
          heightContent="calc(100vh - 240px)"
          isCopyBtn={true}
          isDone={AIAnswer.done}
          isHidden={false}
          isOpenModal={true}
          isValues={isValues}
        />
      </Box>
      {/* <ModalAIAdvice
        AIAnswer={AIAnswer}
        askAIOptions={askAIOptions}
        conversationId={conversationId}
        isOpen={expanded}
        isValues={Boolean(values.askAI)}
        setAIAnswer={setAIAnswer}
        setConversationId={setConversationId}
        setExpanded={setExpanded}
        setShowConfirmModal={setShowConfirmModal}
        setValueAskAI={value => setFieldValue('askAI', value)}
        value={values.askAI}
        onClose={handleCloseModal}
      /> */}
      <ModalConfirmAIAdvice
        conversationId={conversationId}
        isOpen={showConfirmModal}
        setExpanded={setExpanded}
        setShowConfirmModal={setShowConfirmModal}
      />
    </FormikProvider>
  )
}
