import { Flex, Text } from '@chakra-ui/react'
import { InputField, SelectField } from 'components/atoms'
import React, { FC } from 'react'
import { FormikErrors } from 'formik'
import { Option } from 'types/conmon'

import { DataChartType } from 'types/chart'
import CustomMultiSelect from 'components/atoms/MultiSelectField/CustomMultiSelect'
import { optionDisplaySize, optionNumberOfWord, optionsSortBy } from './data'
import { FormValues } from './hook'

type WordMapOptionsProps = {
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined,
  ) => Promise<void> | Promise<FormikErrors<FormValues>>
  values: FormValues
  analysisMenuValue: string
  multiCompanyOptions: Option[]
  handleOffSwitch: () => void
  setDataChart: React.Dispatch<React.SetStateAction<DataChartType>>
}

const WordMapOptions: FC<WordMapOptionsProps> = ({
  setFieldValue,
  values,
  analysisMenuValue,
  multiCompanyOptions,
  handleOffSwitch,
  setDataChart,
}) => {
  return (
    <Flex
      align={'center'}
      gap={'24px'}
      justifyContent={'space-between'}
      mb={'16px'}
      w={'100%'}
    >
      <InputField
        name="search_key"
        placeholder="フリーワード検索"
        width={'242px'}
        onChange={e => setFieldValue('search_key', e.target.value)}
      />
      <Flex gap={'24px'} justifyContent={'end'} w={'calc(100% - 266px)'}>
        {analysisMenuValue === '1' && (
          <CustomMultiSelect
            defaultValue={values.multi_company}
            labelEmptyOption="比較する企業がありません"
            labelTooltip="項目は２つまで選択可能です"
            options={multiCompanyOptions}
            placeholder="会社名を選択"
            setValue={(options: Option[]) =>
              setFieldValue('multi_company', options)
            }
          />
        )}

        {analysisMenuValue === '2' && (
          <SelectField
            height="40px"
            isInForm={false}
            name="sort_by"
            options={optionsSortBy}
            placeholder="並び順"
            valueOption={optionsSortBy.find(it => it.value === values.sort_by)}
            width="106px"
            onChange={e => {
              setFieldValue('sort_by', e?.value)
            }}
          />
        )}
        <Flex gap={'24px'} minW={'286px'}>
          <Flex align={'center'} gap={1}>
            <Text
              color={'#6C7275'}
              fontSize="12px"
              fontWeight="normal"
              lineHeight="shorter"
              minW="fit-content"
            >
              表示サイズ
            </Text>
            <SelectField
              height="40px"
              isInForm={false}
              name="display_size"
              options={optionDisplaySize}
              placeholder=""
              valueOption={optionDisplaySize.find(
                it => it.value === values.display_size,
              )}
              width="73px"
              onChange={e => {
                setFieldValue('display_size', e?.value)
              }}
            />
          </Flex>
          <Flex align={'center'} gap={1}>
            <Text
              color={'#6C7275'}
              fontSize="12px"
              fontWeight="normal"
              lineHeight="shorter"
              minW="fit-content"
            >
              単語数
            </Text>
            <SelectField
              height="40px"
              isInForm={false}
              name="number_of_word"
              options={optionNumberOfWord}
              placeholder=""
              valueOption={optionNumberOfWord.find(
                it => it.value === values.number_of_word,
              )}
              width="85px"
              onChange={e => {
                if (values.number_of_word === e?.value) return
                setFieldValue('number_of_word', e?.value)
                setDataChart(prev => ({
                  ...prev,
                  wordLesChart: {
                    dataEchart: [],
                    dataWordCloud: [],
                    hashed_data: '',
                  },
                  dataTimeLine: {
                    data: [],
                    hashed_data: '',
                  },
                  loading: false,
                }))
                handleOffSwitch()
              }}
            />
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default WordMapOptions
