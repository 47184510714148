import { Box, Flex, Image, Text } from '@chakra-ui/react'
import { FC } from 'react'
import { icons } from 'constant'
import { CompanyResponse } from 'services/companyServices'
import { get, groupBy, isEmpty } from 'lodash'
import moment from 'moment'
import { fomatPrice, fomatPriceToFixed } from 'utils/fomatNumber'
import { CompanyForm } from './CompanyForm'

type SelectFieldProps = {
  data: CompanyResponse
  options: { label: string; value: string | number }[]
}
/**
 * BusinessInfoDetail components
 * @constructor
 */
export const BusinessInfoDetail: FC<SelectFieldProps> = ({ data, options }) => {
  const dataTab = [
    {
      label: '売上高',
      name_1: 'Sales_Ordinance_2_3__c',
      name_2: 'Sales_Ordinance_3_3__c',
      name_3: 'Sales_Ordinance_4_3__c',
    },
    {
      label: '営業利益',
      name_1: 'Operating_Profit_Ordinance_2_3__c',
      name_2: 'Operating_Profit_Ordinance_3_3__c',
      name_3: 'Operating_Profit_Ordinance_4_3__c',
    },
    {
      label: '経常利益',
      name_1: 'Ordinary_Profit_Ordinance_2_3__c',
      name_2: 'Ordinary_Profit_Ordinance_3_3__c',
      name_3: 'Ordinary_Profit_Ordinance_4_3__c',
    },
    {
      label: '当期純利益',
      name_1: 'Net_Income_Ordinance_2_3__c',
      name_2: 'Net_Income_Ordinance_3_3__c',
      name_3: 'Net_Income_Ordinance_4_3__c',
    },
    {
      label: '申告所得',
      name_1: 'Declared_Income_Ordinance_2_3__c',
      name_2: 'Declared_Income_Ordinance_3_3__c',
      name_3: 'Declared_Income_Ordinance_4_3__c',
    },
  ]

  const result = groupBy(data.offices, ({ office_type: { id } }) => id)
  const convertData = Object.keys(result).map(key => result[key])

  const date = data.created_at
    ? `(${moment(data.created_at).format('YYYY')}年${moment(
        data.created_at,
      ).format('MM')}月${moment(data.created_at).format('DD')}日現在)`
    : ''

  const formatDataRatio = (newValue: number, oldValue: number) => {
    if (!oldValue && newValue) {
      return 100
    }
    if (oldValue && !newValue) {
      return -100
    }
    if (newValue - oldValue === 0 || (!newValue && !oldValue)) {
      return 0
    }
    const value = ((newValue - oldValue) / oldValue) * 100
    return value % 1 === 0 ? Number(value) : Number(value.toFixed(1))
  }
  return (
    <>
      <Box id="1">
        <Text
          alignItems="center"
          display="flex"
          fontSize="18px"
          fontWeight="700"
          gap="8px"
          lineHeight="24px"
        >
          <Image src={icons.COMPANY} />
          事業情報
        </Text>
        <CompanyForm
          fieldform={[
            {
              label: '事業内容',
              components: (
                <Flex flexDirection="column" gap="30px">
                  {!isEmpty(data?.business_activity)
                    ? data?.business_activity.map((it, key) => (
                        <Flex key={key} flexDirection="column" gap="20px">
                          <Text
                            fontSize="14px"
                            lineHeight="24px"
                            whiteSpace="pre-wrap"
                          >
                            ■
                            {it?.category_id &&
                              options.find(
                                o =>
                                  Number(o.value) === Number(it?.category_id),
                              )?.label}
                          </Text>
                          <Text
                            fontSize="14px"
                            lineHeight="24px"
                            whiteSpace="pre-wrap"
                          >
                            {it.description}
                          </Text>
                        </Flex>
                      ))
                    : data.description ?? '-'}
                </Flex>
              ),
              isBackground: 'white.50',
            },
            {
              label: '事業所',
              components: (
                <Flex flexDirection="column" gap="30px">
                  <Box>
                    {!isEmpty(convertData)
                      ? convertData.map((it, key) => (
                          <Box key={key}>
                            <Text fontSize="14px" lineHeight="24px">
                              {` ■${it[0].office_type.name}`}
                            </Text>
                            <Text
                              fontSize="14px"
                              lineHeight="24px"
                              whiteSpace="normal"
                            >
                              {it.map(it => it.city?.name_jp).join('、')}
                            </Text>
                          </Box>
                        ))
                      : '-'}
                  </Box>
                </Flex>
              ),
              isBackground: 'white.50',
            },
            {
              label: '仕入先',
              components: (
                <Flex flexDirection="column" gap="30px">
                  <Flex>
                    <Text fontSize="14px" lineHeight="24px">
                      {!isEmpty(data.customer_company)
                        ? data.customer_company.map(it => it.name).join('、')
                        : '-'}
                    </Text>
                  </Flex>
                </Flex>
              ),
              isBackground: 'white.50',
            },
            {
              label: '得意先',
              components: (
                <Flex flexDirection="column" gap="30px">
                  <Box>
                    <Text fontSize="14px" lineHeight="24px">
                      {!isEmpty(data.supplier_company)
                        ? data.supplier_company.map(it => it.name).join('、')
                        : '-'}
                    </Text>
                  </Box>
                </Flex>
              ),
              isBackground: 'white.50',
            },
          ]}
          marginTop="16px"
        />
      </Box>

      <Box id="2" mt="32px">
        <Text
          alignItems="center"
          display="flex"
          fontSize="18px"
          fontWeight="700"
          gap="8px"
          lineHeight="24px"
        >
          <Image src={icons.USER} />
          人事情報
        </Text>
        <CompanyForm
          fieldform={[
            {
              label: '役員',
              components: (
                <>
                  {!isEmpty(data.company_employees)
                    ? data.company_employees.map((it, key) => (
                        <Flex key={key} gap="5px">
                          <Text fontSize="14px" lineHeight="24px">
                            {it?.position?.name}
                          </Text>
                          <Text
                            display="flex"
                            fontSize="14px"
                            gap="14px"
                            lineHeight="24px"
                          >
                            <Text>{it.first_name_kanji ?? ''}</Text>
                            <Text>{it?.last_name_kanji ?? ''}</Text>
                          </Text>
                        </Flex>
                      ))
                    : '-'}
                </>
              ),
              isBackground: 'white.50',
            },
            {
              label: '従業員数',
              components: (
                <Text fontSize="14px" lineHeight="24px">
                  {fomatPrice(Number(data.number_employees) ?? 0)}
                  名（グループ総数　
                  {fomatPrice(Number(data.total_groups ?? 0))}
                  名）{date}
                </Text>
              ),
              isBackground: 'white.50',
            },
            {
              label: '平均年齢',
              components: (
                <Text fontSize="14px" lineHeight="24px">
                  {data.average_age ? `${data.average_age}歳` : '-'}
                </Text>
              ),
              isBackground: 'white.50',
            },
            {
              label: '平均年収',
              components: (
                <Text fontSize="14px" lineHeight="24px">
                  {data.average_annual_income
                    ? `${fomatPriceToFixed(
                        Number(data.average_annual_income),
                      )}千円`
                    : '-'}
                </Text>
              ),
              isBackground: 'white.50',
            },
          ]}
          marginTop="16px"
        />
      </Box>

      <Box id="3" mt="32px">
        <Text
          alignItems="center"
          display="flex"
          fontSize="18px"
          fontWeight="700"
          gap="8px"
          lineHeight="24px"
        >
          <Image src={icons.MONEY_BILL} />
          財務情報
        </Text>

        <CompanyForm
          fieldform={[
            {
              label: '取引銀行',
              components: (
                <Flex>
                  <Text fontSize="14px" lineHeight="24px">
                    {data.performance_trends?.Banks_Transaction__c &&
                    typeof data.performance_trends?.Banks_Transaction__c ===
                      'object'
                      ? data?.performance_trends?.Banks_Transaction__c.map(
                          it => it.name,
                        ).join('、')
                      : '-' || data.performance_trends?.Banks_Transaction__c}
                  </Text>
                </Flex>
              ),
              isBackground: 'white.50',
            },
            {
              label: '決算月',
              components: (
                <Text fontSize="14px" lineHeight="24px">
                  {data.performance_trends?.Fiscal_Closing_Month__c
                    ? data.performance_trends?.Fiscal_Closing_Month__c +
                      '月末日'
                    : '-'}
                </Text>
              ),
              isBackground: 'white.50',
            },
            {
              label: '業績推移',
              components: (
                <Flex flexDirection="column">
                  <Flex gap="4px">
                    <Box w="100px">
                      <Text
                        borderBottom="1px solid"
                        lineHeight="24px"
                        p="4px"
                        textAlign="center"
                      >
                        (単位：千円)
                      </Text>
                    </Box>
                    <Text
                      borderBottom="1px solid"
                      lineHeight="24px"
                      minW="200px"
                      p="4px"
                      textAlign="center"
                    >
                      令2.3
                    </Text>
                    <Text
                      borderBottom="1px solid"
                      lineHeight="24px"
                      minW="200px"
                      p="4px"
                      textAlign="center"
                    >
                      令3.3
                    </Text>
                    <Text
                      borderBottom="1px solid"
                      lineHeight="24px"
                      minW="200px"
                      p="4px"
                      textAlign="center"
                    >
                      令4.3
                    </Text>
                  </Flex>
                  {dataTab.map((it, key) => {
                    const checkBg = key % 2 === 0
                    return (
                      <Flex key={key} gap="4px">
                        <Text
                          alignItems="center"
                          bgColor={checkBg ? '' : '#F4F4F4'}
                          display="flex"
                          justifyContent="center"
                          lineHeight="24px"
                          p="4px 0"
                          w="100px"
                        >
                          {it.label}
                        </Text>
                        <Text
                          bgColor={checkBg ? '' : '#F4F4F4'}
                          borderBottom="1px solid #E8ECEF"
                          lineHeight="32px"
                          minW="200px"
                          px="4px"
                          textAlign="right"
                        >
                          {get(data.performance_trends, it.name_1)
                            ? fomatPrice(
                                get(data.performance_trends, it.name_1),
                              )
                            : '-'}
                        </Text>
                        <Flex alignItems="center" gap="4px" minW="200px">
                          <Text
                            bgColor={checkBg ? '' : '#F4F4F4'}
                            borderBottom="1px solid #E8ECEF"
                            height="100%"
                            lineHeight="32px"
                            minW="108px"
                            px="4px"
                            textAlign="right"
                          >
                            {get(data.performance_trends, it.name_2)
                              ? fomatPrice(
                                  get(data.performance_trends, it.name_2),
                                )
                              : '-'}
                          </Text>
                          <Text
                            bgColor={checkBg ? '' : '#F4F4F4'}
                            borderBottom="1px solid #E8ECEF"
                            color={
                              Number(
                                formatDataRatio(
                                  get(data.performance_trends, it.name_2),
                                  get(data.performance_trends, it.name_1),
                                ),
                              ) < 0
                                ? '#F75555'
                                : Number(
                                    formatDataRatio(
                                      get(data.performance_trends, it.name_2),
                                      get(data.performance_trends, it.name_1),
                                    ),
                                  ) === 0
                                ? ''
                                : '#0FB93E'
                            }
                            fontSize="12px"
                            lineHeight="32px"
                            px="4px"
                            textAlign="right"
                            w="88px"
                          >
                            {formatDataRatio(
                              get(data.performance_trends, it.name_2),
                              get(data.performance_trends, it.name_1),
                            ) === 0
                              ? '-'
                              : `(${
                                  formatDataRatio(
                                    get(data.performance_trends, it.name_2),
                                    get(data.performance_trends, it.name_1),
                                  ) > 0
                                    ? '+'
                                    : '△'
                                }
                               ${formatDataRatio(
                                 get(data.performance_trends, it.name_2),
                                 get(data.performance_trends, it.name_1),
                               )
                                 .toString()
                                 .replace('-', '')}
                                 %)`}
                          </Text>
                        </Flex>
                        <Flex alignItems="center" gap="4px" minW="200px">
                          <Text
                            bgColor={checkBg ? '' : '#F4F4F4'}
                            borderBottom="1px solid #E8ECEF"
                            height="100%"
                            lineHeight="32px"
                            px="4px"
                            textAlign="right"
                            w="108px"
                          >
                            {get(data.performance_trends, it.name_3)
                              ? fomatPrice(
                                  get(data.performance_trends, it.name_3),
                                )
                              : '-'}
                          </Text>
                          <Text
                            bgColor={checkBg ? '' : '#F4F4F4'}
                            borderBottom="1px solid #E8ECEF"
                            color={
                              Number(
                                formatDataRatio(
                                  get(data.performance_trends, it.name_3),
                                  get(data.performance_trends, it.name_2),
                                ),
                              ) < 0
                                ? '#F75555'
                                : Number(
                                    formatDataRatio(
                                      get(data.performance_trends, it.name_3),
                                      get(data.performance_trends, it.name_2),
                                    ),
                                  ) === 0
                                ? ''
                                : '#0FB93E'
                            }
                            fontSize="12px"
                            lineHeight="32px"
                            px="4px"
                            textAlign="right"
                            w="88px"
                          >
                            {formatDataRatio(
                              get(data.performance_trends, it.name_3),
                              get(data.performance_trends, it.name_2),
                            ) === 0
                              ? '-'
                              : `(${
                                  formatDataRatio(
                                    get(data.performance_trends, it.name_3),
                                    get(data.performance_trends, it.name_2),
                                  ) > 0
                                    ? '+'
                                    : '△'
                                }
                               ${formatDataRatio(
                                 get(data.performance_trends, it.name_3),
                                 get(data.performance_trends, it.name_2),
                               )
                                 .toString()
                                 .replace('-', '')}
                              %)`}
                          </Text>
                        </Flex>
                      </Flex>
                    )
                  })}
                </Flex>
              ),
              isBackground: 'white.50',
            },
          ]}
          marginTop="16px"
        />
      </Box>
    </>
  )
}
