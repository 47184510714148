import { Box, Flex, Text, SkeletonText, Button } from '@chakra-ui/react'
import { SelectField } from 'components/atoms'
import React, { FC } from 'react'
import { Modal, Button as CusButton } from 'components/fromAdvisor/elements'
import { Option } from 'types/conmon'
import ReactMarkdown from '../ReactMarkdown'

type ModalTalkStoryProps = {
  story: string
  isOpen: boolean
  conversationId: number | null
  isDisableSelect: boolean
  isDisableButton: boolean
  options: { [key: string]: Option[] }
  stateAIAnswer: { loading: boolean; done: boolean }
  valueSelected: {
    industry: number | null
    story: number | null
  }
  onClose: () => void
  onSendChatAi: () => void
  onDisconnectChatAi: () => void
  setShowConfirmModal: React.Dispatch<React.SetStateAction<boolean>>
  setValueSelected: React.Dispatch<
    React.SetStateAction<{
      industry: number | null
      story: number | null
    }>
  >
}

/**
 * ModalTalkStory molecules
 * @constructor
 */
const ModalTalkStory: FC<ModalTalkStoryProps> = ({
  story,
  isOpen,
  options,
  valueSelected,
  stateAIAnswer,
  conversationId,
  isDisableSelect,
  isDisableButton,
  onClose,
  onSendChatAi,
  setValueSelected,
  onDisconnectChatAi,
  setShowConfirmModal,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      isShowIconClose={false}
      maxWidth="667px"
      onClose={onClose}
    >
      <Box
        bg="white.50"
        borderRadius="20px"
        boxSizing="border-box"
        padding="24px 24px 24px 32px"
        w="100%"
      >
        <Text
          fontSize={'18px'}
          fontWeight={'bold'}
          letterSpacing={'-0.02em'}
          lineHeight={'40px'}
          minW={'fit-content'}
          px={'8px'}
        >
          トークネタ
        </Text>
        <Flex gap="16px" mb={'12px'} mt={'4px'}>
          <SelectField
            heightListOptions="224px"
            isDisabled={isDisableSelect}
            isInForm={false}
            minHeight="40px"
            name="selectIndustry"
            options={options.industry}
            placeholder="業界"
            sizePlaceholder="14px"
            valueOption={options.industry.find(
              it => it.value === valueSelected.industry,
            )}
            width="100%"
            onChange={e => {
              if (e?.value !== valueSelected.industry) {
                setValueSelected(prev => ({
                  ...prev,
                  industry: Number(e?.value),
                }))
              }
            }}
          />
          <SelectField
            heightListOptions="224px"
            isDisabled={isDisableSelect}
            isInForm={false}
            minHeight="40px"
            name="selectTalkStory"
            options={options.story}
            placeholder="トークテーマ"
            sizePlaceholder="14px"
            valueOption={options.story.find(
              it => it.value === valueSelected.story,
            )}
            width="100%"
            onChange={e => {
              if (e?.value !== valueSelected.story) {
                setValueSelected(prev => ({ ...prev, story: Number(e?.value) }))
              }
            }}
          />
          {!stateAIAnswer.loading && !stateAIAnswer.done ? (
            <CusButton
              bgColor={'white'}
              border={'1px solid #0084ff'}
              color="blue.900"
              fontSize={'16px'}
              fontWeight={'bold'}
              h={'40px'}
              letterSpacing={'-0.02px'}
              lineHeight={'shorter'}
              minW={'122px'}
              px={5}
              text="キャンセル"
              onClick={onDisconnectChatAi}
            />
          ) : (
            <CusButton
              bgColor={'blue.900'}
              border={'none'}
              color="white.50"
              fontSize={'16px'}
              fontWeight={'bold'}
              h={'40px'}
              isDisabled={isDisableSelect || isDisableButton}
              letterSpacing={'-0.02px'}
              lineHeight={'shorter'}
              minW={'122px'}
              px={5}
              text="実行"
              onClick={onSendChatAi}
            />
          )}
        </Flex>
        <Box>
          {stateAIAnswer.loading ? (
            <SkeletonText noOfLines={6} skeletonHeight="4" spacing="4" />
          ) : Boolean(story) ? (
            <Box maxHeight={'425px'} overflow={'auto'}>
              <ReactMarkdown
                bgColor="#FEFEFE"
                data={story}
                isCopyBtn={true}
                isDone={stateAIAnswer.done}
              />
            </Box>
          ) : (
            <Flex justify={'center'} w="100%">
              <Text
                color="black.100"
                fontSize={'16px'}
                fontWeight="normal"
                id="text-element"
                lineHeight="shorter"
                maxHeight={'425px'}
                overflow={'auto'}
                textAlign="justify"
                whiteSpace={'pre-wrap'}
              >
                {'データがありません。'}
              </Text>
            </Flex>
          )}
        </Box>
        <Flex gap="12px" justifyContent="center" mt="24px">
          <Button
            _focusVisible={{}}
            _hover={{
              bgColor: 'blue.900',
            }}
            bgColor="blue.900"
            border="none"
            borderRadius="12px"
            gap="8px"
            height="48px"
            isDisabled={!conversationId}
            maxW="280px"
            padding="12px"
            width="100%"
            onClick={() => {
              onClose()
              setShowConfirmModal(true)
            }}
          >
            <Text
              color="white.50"
              fontSize="md"
              fontWeight="bold"
              letterSpacing="0.2px"
              lineHeight="shorter"
            >
              続けて質問
            </Text>
            <Text
              color="white.50"
              fontSize="xs"
              fontWeight="normal"
              letterSpacing="0.2px"
              lineHeight="shorter"
            >
              (AIチャットへ切替)
            </Text>
          </Button>
          <Button
            _focusVisible={{}}
            _hover={{
              bgColor: 'white.50',
            }}
            bgColor="white.50"
            border="1px solid"
            borderColor="blue.900"
            borderRadius="12px"
            color="blue.900"
            height="48px"
            maxW="280px"
            width="100%"
            onClick={onClose}
          >
            とじる
          </Button>
        </Flex>
      </Box>
    </Modal>
  )
}

export default ModalTalkStory
