import React, { FC, ReactElement, ReactNode, useEffect, useState } from 'react'
import {
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs as TabsChakra,
  TabListProps as TabListChakraProps,
  TabIndicator,
} from '@chakra-ui/tabs'
import { Box, Flex, Text, useTheme } from '@chakra-ui/react'
import { useGetQueryParams } from 'navigations/getQueryParams'
import { ROUTES } from 'constant/router'
import { navigateWithQueryParams } from 'navigations/navigateWithQueryParams'
import { useLocation, useNavigate } from 'react-router-dom'
import { QuestionCircle } from 'components/atoms/Icons'
import { useSelector } from 'react-redux'
import { auth } from 'context/Auth/auth.selector'
import ModalMilizeAgent from '../templates/chatAI/tabs/IframeTab/ModalMilizeAgent'

export type TabsType = {
  id: number
  label?: string
  component?: ReactNode
  isDisabled?: boolean
}

type TabsProps = TabListChakraProps & {
  tabs: TabsType[]
  notifyElement?: ReactElement
}

const DEFAULT_TAB = 0

/**
 * CustomTabs component page.
 * @return {JSX.Element} CustomTabs component.
 */
export const CustomTabs: FC<TabsProps> = (props): ReactElement => {
  const navigate = useNavigate()
  const location = useLocation()
  const userLogin = useSelector(auth)

  const queryParams = useGetQueryParams(location)
  const tab = queryParams.get('tab')
  const indexTab = Number(tab) ? Number(tab) : DEFAULT_TAB
  const theme = useTheme()
  const [isShowModal, setIsShowModal] = useState(
    !Boolean(userLogin?.disable_show_tutorial),
  )

  /**
   * @returns function that handle change tab
   */
  const handleChangeTab = (index: number) => {
    navigateWithQueryParams(navigate, `${ROUTES.CHAT_AI}`, 'tab', index)
  }

  const handleCloseModal = () => {
    setIsShowModal(false)
  }

  const handleOpenModal = () => {
    setIsShowModal(true)
  }

  useEffect(() => {
    if (indexTab !== 4) {
      setIsShowModal(!Boolean(userLogin?.disable_show_tutorial))
    }
  }, [indexTab, userLogin?.disable_show_tutorial])

  return (
    <>
      <TabsChakra
        isLazy
        index={indexTab}
        variant="unstyled"
        onChange={handleChangeTab}
      >
        <Box
          bg={'white.50'}
          borderBottomColor={'purple.250'}
          borderBottomWidth={theme.space.px}
          borderTopLeftRadius={4}
          borderTopRightRadius={4}
          pl={6}
          position={'relative'}
          pr={62}
        >
          <TabList {...props}>
            {props.tabs.map(({ id, label, isDisabled }) => (
              <Tab
                key={id}
                _selected={{
                  color: 'black.100',
                  bg: 'white.50',
                  fontWeight: 'semibold',
                }}
                borderBottom={'none'}
                borderTopRadius={'3xl'}
                color={'blue.900'}
                fontSize={'sm'}
                fontWeight={'normal'}
                h={14}
                isDisabled={isDisabled}
                lineHeight={theme.lineHeights.shorter}
                w={34}
              >
                {label}
              </Tab>
            ))}
          </TabList>
          {indexTab === 4 && (
            <Flex
              alignItems={'center'}
              backgroundColor={'transparent'}
              gap={'6px'}
              h={'32px'}
              position={'absolute'}
              right={'32px'}
              top={'12px'}
            >
              <QuestionCircle transform={'scale(1.5)'} />
              <Text
                color={'blue.900'}
                cursor={'pointer'}
                fontSize={'14px'}
                fontWeight={'medium'}
                lineHeight={'shorter'}
                onClick={handleOpenModal}
              >
                チュートリアルを見る
              </Text>
            </Flex>
          )}
        </Box>
        <TabIndicator bg="blue.900" height={0.5} mt={-0.5} />
        {props.notifyElement}
        <TabPanels>
          {props.tabs.map(({ id, component }) => (
            <TabPanel key={id} p={0}>
              {component}
            </TabPanel>
          ))}
        </TabPanels>
      </TabsChakra>
      {indexTab === 4 && (
        <ModalMilizeAgent isOpen={isShowModal} onClose={handleCloseModal} />
      )}
    </>
  )
}
